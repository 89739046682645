import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { FaChevronCircleDown, FaChevronCircleUp } from "react-icons/fa";




function RunPredict() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let initialDisplay  = params.get('display') || 'default';
    let matchid = params.get('matchid');
    let widgetwidth = params.get('width') || '100%';
    let widgetbackground = params.get('background');
    let widgetborder = params.get('border');
    let widgetsponsor = params.get('sponsor') || '';
    let widgetanimate = params.get('animate') || '';
  
    let team1WinPercentage=0;
    let team2WinPercentage=0;

    const [runChangeDirection, setRunChangeDirection] = useState(null);
    const [showAnimation, setShowAnimation] = useState(false);
    const prevRuns = useRef();

    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(initialDisplay);
    const [Team1Display, setTeam1Display] = useState('');
    const [Team2Display, setTeam2Display] = useState('');
    const [Team1Logo, setTeam1Logo] = useState('');
    const [Team2Logo, setTeam2Logo] = useState('');
    const [powerbar, setPowerbar] = useState(false);
    const [progressBarColor, setprogressBarColor] = useState('#0581CA');
    const [progressBarColorAlt, setprogressBarColorAlt] = useState('#fff');
    const [bColor, setbColor] = useState('red');
    const percPresicion = useRef(1);
    const [bodyColor, setBodyColor] = useState("#fff");
    const [drawDisplay, setdrawDisplay] = useState(false);
    const [showFlags, setshowFlags] = useState(false);
    const [currentBatting, setCurrentBatting] = useState('');
    const [startRuns, setStartRuns] = useState('');
    const [currRuns, setCurrRuns] = useState('');
    const [currentBackground, setCurrentBackground] = useState('#000');

    const fetchData = async () => {
        if (id) {
          try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getBroadcastId&id=${id}`);
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.answers[0].question_type.trim()==='predict') {
                matchid=responseData.answers[0].answers.match_id.trim();
                setDisplay(responseData.answers[0].answers.graphic.trim());
                setTeam1Display(responseData.answers[0].answers.name_override[0].trim());
                setTeam2Display(responseData.answers[0].answers.name_override[1].trim());       
                setTeam1Logo(responseData.answers[0].answers.logo_override[0].trim());
                setTeam2Logo(responseData.answers[0].answers.logo_override[1].trim());
                setPowerbar(responseData.answers[0].answers.power_bar);
                setbColor(responseData.answers[0].answers.color);
                setprogressBarColor(responseData.answers[0].answers.pb_color);
                setdrawDisplay(responseData.answers[0].answers.draw);
                setshowFlags(responseData.answers[0].answers.show_flags);
                const progressBarColorAlt = responseData.answers[0].answers.pb_color_alt || '#fff';
                setprogressBarColorAlt(progressBarColorAlt);
                percPresicion.ref=responseData.answers[0].answers.percentage_precision;
                const pageBodyColor = responseData.answers[0].answers.body_background;
                if (pageBodyColor) {
                  document.body.style.backgroundColor = pageBodyColor;
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
    
        } else {
          //alert("x");
          //fetchMatchData();
        }
        fetchRunsPredict();
        setLoading(false);
    };
    const fetchRunsPredict = async () => {
        try {
            const response = await axios.post(
                `https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getCurrentRunPredict&id=${id}`,
                {},
                {
                  headers: {
                    'x-api-key': '4ICgYDw7KLM9Ku2Qc14T0Yi1YbBKE5paH21KP4aUSnewXhQVIQ7f9xixbh1OmEex',
                  },
                }
            );
            if (response.status === 200) {
                const newRuns = parseInt(response.data.current);
                if (response.data.currentBat==='home') {
                    setCurrentBatting(response.data.names[0]);
                } else {
                    setCurrentBatting(response.data.names[1]);
                }
                setStartRuns(parseInt(response.data.start));
                if (prevRuns.current !== undefined) {
                  if (newRuns>prevRuns.current) {
                    setRunChangeDirection('up');
                    setShowAnimation(true);
                  } else if (newRuns<prevRuns.current) {
                    setRunChangeDirection('down');
                    setShowAnimation(true);
                  }
                }
                setCurrRuns(newRuns);
                prevRuns.current = newRuns;
                if (newRuns>parseInt(response.data.start)) {
                    setCurrentBackground('#17680f');
                }
                if (newRuns<parseInt(response.data.start)) {
                    setCurrentBackground('#d91818');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };
        
    useEffect(() => {
        prevRuns.current = currRuns;
        fetchData();
      }, []);
    useEffect(() => {
        const interval = setInterval(fetchRunsPredict, 5000)
        return () => clearInterval(interval)
    }, [matchid]);
       
    return (
        <>
        {loading ? (
          <div></div>
        ) :  (
          <>
          {display==='tes2024' ? (
            <div id='tes2024' style={{width: `${widgetwidth}`}}>
              <div className='winpredict' style={{backgroundColor: `#${widgetbackground}`, borderColor: `#${widgetborder}`, animation: `${widgetanimate==='true' ? ('slideUpBackground 0.5s ease-out forwards') : ( 'none' )}`}}>
                  <div className='line' style={
                                          {
                                            animation: `${widgetanimate==='true' ? ('slideUpLines 0.5s ease-out forwards') : ( 'none' )}`,
                                            opacity: `${widgetanimate==='true' ? ('0') : ( '1' )}`
                                            }}>
                    <div className='col teamname'>EXPECTED SCORE</div>
                  </div>
                  <div className='line' style={
                                          {
                                            animation: `${widgetanimate==='true' ? ('slideUpLines 0.5s ease-out forwards') : ( 'none' )}`,
                                            opacity: `${widgetanimate==='true' ? ('0') : ( '1' )}`
                                            }}>
                    <div className='col teamwin'>
                      <div className='theRuns'>
                        {showAnimation ? (
                        <div
                            className={`arrow-animation ${runChangeDirection}`}
                            onAnimationEnd={() => setShowAnimation(false)}
                            style={{ animationName: runChangeDirection === 'up' ? 'slideUp' : 'slideDown' }}
                        >
                          {runChangeDirection === 'up' ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                        </div>
                        ) : (
                          <div>{currRuns}</div>
                        )}
                      </div>

                    </div>
                  </div>
                  {widgetsponsor.toLowerCase()==='dafabet' ? (
                    <div className='sponsor'>
                      <img src='/imgs/dafabet_logo.png' style={{maxHeight: '100px'}} />
                    </div>
                  ) : ( null )}
              </div>        
            </div>
          ) : (
            <div id='minimal' class='runpredict'>
                <div class='container'>
                  <div class='row'>
                    <div id='runsheader' class='col-12' style={{backgroundColor: bColor}}>
                      <div class='team withdraw'>
                        RUNS PREDICTION<br/>{currentBatting}
                      </div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-6 text-center rightborder' style={{backgroundColor: bColor}}>
                        <div class='theRuns'>{startRuns}</div>
                        <div class='subhead'>START</div>
                    </div>
                    <div class='col-6 text-center leftborder' style={{backgroundColor: currentBackground}}>
                      <div className='theRuns'>
                        {showAnimation ? (
                        <div
                            className={`arrow-animation ${runChangeDirection}`}
                            onAnimationEnd={() => setShowAnimation(false)}
                            style={{ animationName: runChangeDirection === 'up' ? 'slideUp' : 'slideDown' }}
                        >
                          {runChangeDirection === 'up' ? <FaChevronCircleUp /> : <FaChevronCircleDown />}
                        </div>
                        ) : (
                          <div>{currRuns}</div>
                        )}
                      </div>
                      <div class='subhead'>CURRENT</div>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col text-center powerby' style={{borderColor: bColor}}>
                      {showFlags!==true ? (
                      <img class='img-fluid' src='/imgs/IC_logo_sm.png' alt='Intelligent Cricket' />
                      ) : (
                        <div class='row ng'>
                          <div class='col-2 text-start'>
                            <img class='flagicon' src={Team1Logo} alt={Team1Display} />                   
                          </div>
                          <div class='col-8'>
                            <img class='img-fluid' src='/imgs/IC_logo_sm.png' alt='Intelligent Cricket' />
                          </div>
                          <div class='col-2 text-end'>
                            <img class='flagicon' src={Team2Logo} alt={Team2Display} />                   
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
          )}
          </>
        )}
        </>
    )


}

export default RunPredict;