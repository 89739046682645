import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { redirect, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
function createGradient(getColor) {
  return (ctx) => {
    const value = ctx.dataset.data[ctx.dataIndex];
    if (typeof value !== 'number') {
      return null;
    }
    return getColor(ctx);
  };
}
function getOrdinalSuffix(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;

  if (remainder >= 11 && remainder <= 13) {
    return number + 'th';
  } else {
    const suffix = suffixes[number % 10] || 'th';
    return number + suffix;
  }
}
function PredictionHistory() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let broadcast_hex = params.get('broadcast');
    let initialDisplay  = params.get('display') || 'default';
    let chartHeight = params.get('height')+'vh' || '40vh';
    let thisInnings = params.get('innings') || 1;

    const [matchid, setMatchid] = useState('');;
    const [team1WinPercentage, setTeam1WinPercentage] = useState(null);
    const [team2WinPercentage, setTeam2WinPercentage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(initialDisplay);
    const [Team1Display, setTeam1Display] = useState('');
    const [Team2Display, setTeam2Display] = useState('');
    const [Team1Logo, setTeam1Logo] = useState('');
    const [Team2Logo, setTeam2Logo] = useState('');
    const [PowerBarData, setPowerBarData] = useState([]);
    const [Winner, setWinner] = useState('');
    const [powerbar, setPowerbar] = useState(false);
    const [progressBarColor, setprogressBarColor] = useState('#0581CA');
    const [progressBarColorAlt, setprogressBarColorAlt] = useState('#fff');
    const [bColor, setbColor] = useState('red');
    const [percPresicion, setpercPresicion] = useState(1);
    const [bodyColor, setBodyColor] = useState("#fff");
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [batting1, setbatting1] = useState(null);
    const [rtnInnings, setrtnInnings] = useState('1');

    const fetchData = async () => {
        if (id && !matchid) {
          try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getBroadcastId&id=${id}`);
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.answers[0].question_type.trim() === 'predict') {
                const newMatchid = responseData.answers[0].answers.match_id.trim();
                setMatchid(newMatchid); 
                setDisplay(responseData.answers[0].answers.graphic.trim());
                setTeam1Display(responseData.answers[0].answers.name_override[0].trim());
                setTeam2Display(responseData.answers[0].answers.name_override[1].trim());
                setTeam1Logo(responseData.answers[0].answers.logo_override[0].trim());
                setTeam2Logo(responseData.answers[0].answers.logo_override[1].trim());
                setPowerbar(responseData.answers[0].answers.power_bar);
                setbColor(responseData.answers[0].answers.color);
                setprogressBarColor(responseData.answers[0].answers.pb_color);
                const progressBarColorAlt = responseData.answers[0].answers.pb_color_alt || '#fff';
                setprogressBarColorAlt(progressBarColorAlt);
                setpercPresicion(responseData.answers[0].answers.percentage_precision);
                const pageBodyColor = responseData.answers[0].answers.body_background;
                if (pageBodyColor) {
                  document.body.style.backgroundColor = pageBodyColor;
                }
                fetchMatchData();
              }


            }
          } catch (error) {
            console.error(error);
          }
          setLoading(false);
        }
      };

    const fetchMatchData= async () => {
      
        try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=powerbarhistory&id=${id}&innings=${thisInnings}`);
            if (response.status === 200) {
              setbatting1(response.data.Batting1);
              setrtnInnings(response.data.Innings);
              for (var i = 0; i < response.data.Team1.length; i++) {
                if (response.data.Team1[i] !== null) {
                  response.data.Team1[i] -= 50;
                }
              }
              for (var i = 0; i < response.data.Team2.length; i++) {
                if (response.data.Team2[i] !== null) {
                  response.data.Team2[i] -= 50;
                }
              }
              const dataPoints = response.data.Team1;
              const labels = [];
              let current = 1;
              let exp=1;
              while (current <= (response.data.Team2.length / 6)) {
                labels.push(Number(current+"."+exp));
                exp+=1;
                if (exp>6) {
                  current+=1;
                  exp=1;
                }
              }
              const stepSize = 1;

              function createGradient(getColor) {
                return (ctx) => {
                  const value = ctx.dataset.data[ctx.dataIndex];
                  if (typeof value !== 'number') {
                    return null;
                  }
                  return getColor(ctx);
                };
              }
              const chartData = {
               type: 'line',
               labels: labels,
                datasets: [
                  {
                    label: '',
                    data: dataPoints,
                    pointRadius: 2,
                    borderWidth: 1,
                    borderColor: createGradient(ctx => {
                      const currentData = ctx.dataset.data[ctx.dataIndex];
                      const color = currentData >= 0 ? response.data.Colors.team1 : response.data.Colors.team2; 
                      return color;
                    }),
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: createGradient(ctx => {
                      const currentData = ctx.dataset.data[ctx.dataIndex];
                      const color = currentData >= 0 ? response.data.Colors.team1 : response.data.Colors.team2; 
                      return color;
                    }),
                    borderJoinStyle: 'bevel',
                    fill: {
                      target: 'origin',
                      above: `${response.data.Colors.team1}80`,
                      below: `${response.data.Colors.team2}80`,
                    },
                    tension: 0.1
                  },
                ]
              };
              let Wcount = 0;
              let delayed;
              const chartOptions = {
              maintainAspectRatio: false,
              interaction: {
                  mode: 'x',
                },
                scales: {
                  x: {
                    position: 'top',
                    title: {
                      display: true,
                      text: 'Overs',
                      font: {
                        size: 12,
                        weight: 900,
                      }
                    },
                    ticks: {
                      maxRotation: 0,
                      autoSkip: false,
                      callback: (value, index) => {
                        if ((index+1) % 6 !== 0) {
                          return '';
                        }
                        return ((index+1) / 6);
                      }, 
                    },
                  },
                  x2: {
                    position: 'bottom', 
                    display: true,
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                        const ind = context.tick.value;
                        const over = Math.floor((ind+6)/6);
                        const ball=((ind+6) % 6)+1;
                        const thisOB = over +(ball/10);
                        if (response.data.Wickets.includes(thisOB)) {
                          return 3;
                        } else  {
                          return 0;
                        }
                         return 0;
                      },color: function(context) {
                        const ind = context.tick.value;
                        const over = Math.floor((ind+6)/6);
                        const ball=((ind+6) % 6)+1;
                        const thisOB = over +(ball/10);
                        if (response.data.Wickets.includes(thisOB)) {
                          return 'black';
                        } 
                      },
                    },
                    ticks: {
                      autoSkip: false,
                      color: 'white',
                      textStrokeWidth: 8,
                      showLabelBackdrop: true,
                      backdropColor: 'black', 
                      backdropPadding: 5,
                      font: { size: 12, weight: 900 },
                      callback: (value, index) => {
                        const over = Math.floor((index+6)/6);
                        const ball=((index+6) % 6)+1;
                        const thisOB = over +(ball/10);
                        const wicketIndex = response.data.Wickets.indexOf(thisOB) + 1;
                        if (response.data.Wickets.includes(thisOB)) {
                          return 'W';
                        } else {
                          return null;
                        }
                      },
                    },
                  },
                  y: {
                    beginAtZero: true,
                    max: 50,
                    min: -50,
                    ticks: {
                      reverse: true,
                      stepSize: 50, 
                      callback: function(value, index, values) {
                        if (value === -50) {
                          return '100';
                        } else if (value === 50) {
                          return '100';
                        } else if (value === 0) {
                          return '50';
                        } else {
                          return '';
                        }
                      }
                    },
                    title: {
                      display: true,
                      text: 'Win Prediction',
                    },
                    scaleLabel: {
                      display: true,
                      labelString: "Win Prediction Percentage",
                      fontStyle: 'bold',
                    },
                  },
                  responsive: true,
                },
                plugins: {
                  legend: {
                    display: false,
                  }
                },
              };
        
              setChartData(chartData);
              setChartOptions(chartOptions);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const hexToRGB = hex => {
      let alpha = false,
        h = hex.slice(hex.startsWith('#') ? 1 : 0);
      if (h.length === 3) h = [...h].map(x => x + x).join('');
      else if (h.length === 8) alpha = true;
      h = parseInt(h, 16);
      return (
        'rgb' +
        (alpha ? 'a' : '') +
        '(' +
        (h >>> (alpha ? 24 : 16)) +
        ', ' +
        ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
        ', ' +
        ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
        (alpha ? `, ${h & 0x000000ff}` : '') +
        ')'
      );
    };

    useEffect(() => {
        fetchData();
      }, []);


    return (
        <>
        {loading ? (
            <></>
        ) : (
            <>
            <div id='compact'>
              <div id='header' class='container-fluid'>
                <div class='row' style={{borderColor: bColor, backgroundColor: '#fff', borderBottom: 'none' }}>
                  <div class='col-1'>
                    <img class='img-fluid' src='/imgs/IC_logo_sm.png' />
                  </div>
                  <div class='col-5'>WIN PREDICT PROGRESSION</div>
                  <div class='col-6 text-end'>
                      <div><span class='teams' style={{backgroundColor: progressBarColor}}>{Team1Display}</span> vs <span class='teams' style={{backgroundColor: progressBarColorAlt}}>{Team2Display}</span></div>
                      <div class='batting'>({getOrdinalSuffix(rtnInnings)} Innings - {batting1} Batting)</div>
                  </div>
                </div>
              </div>
              <div id='footer' class='container-fluid'>
                  <div class='row powerby' style={{borderColor: bColor}}>
                      <div class='col-12 text-end'>
                      {chartData ? (
                        <div class="chart-container" style={{position: "relative", height: chartHeight, width: "100%"}}>
                          <Line data={chartData} options={chartOptions}/>
                        </div>
                      ) : (
                        <div>Loading chart...</div>
                      )}
                      </div>
                  </div>
              </div>
            </div>            
            </>
        )}
        </>
    );
      

}

export default PredictionHistory;