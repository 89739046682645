import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function Results() {
  const team1 = 67;
  const team2 = 100-team1;

  return (
    <>
    <div id='broadcasting'>
      <div className="container">
        <div className="row">
            <div className="col-12 pt-4">
                <img src='/imgs/onscreen_graphic_blue_top.png' className='img-fluid' />
            </div>
        </div>
        <div className="row g-0 graphic-contents">
            <div className="col-5">
                <div className='contents'>
                    <div class="progress progress-bar-vertical">
                        <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{"height": `${team2}%`}}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-7">
                <div class='contents'>
                    <div className='row'>
                        <div className='col-6'>
                            <img src='/imgs/SteelbacksTicket-100x100.png.webp' className='img-fluid' /><br/>
                        </div>
                        <div className='col-12'>
                            <div class='result-text'>SteelBacks</div>
                            <div class='result-current'>{team1}%</div>
                        </div>    
                    </div>
                    <div className='row mt-4'>
                        <div className='col-6'>
                            <img src='/imgs/WorcestershireWB-65x65.png.webp' className='img-fluid' />
                        </div>
                        <div className='col-12'>
                            <div class='result-text'>Rapids</div>
                            <div class='result-current'>{team2}%</div>
                        </div>    
                    </div>
                </div>
            </div>
            <div className='col-12'>
                <div class='contents text-center pt-5'>
                    <span class='title'>PREDICTION:</span><br/>
                    Who will win the match?
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-12">
                <img src='/imgs/onscreen_graphic_blue_btm.png' className='img-fluid' />
            </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Results;
