import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Reactions() {
  const [reactions, setReactions] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get('https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=reactions');
      setReactions(response.data.reactions);
    }
    fetchData();
  }, []);

  if (reactions.length === 0) {
    return <div></div>;
  }

  return (
    <div className="reaction-container">
      {reactions.map(({ reaction, name }, i) => {
        return (
          <div key={i} className={`reaction reaction-${reaction}`}>
            <div className="reaction-icon"><img src={`/imgs/reaction_${reaction}.png`} /></div>
            <div className="reaction-name">{name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Reactions;
