import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { redirect, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import { TwitterShareButton } from 'react-share';
import { BiCricketBall } from "react-icons/bi";
function ttHover(idx) {
  //console.log(idx);





}
function getOrdinalSuffix(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;

  if (remainder >= 11 && remainder <= 13) {
    return number + 'th';
  } else {
    const suffix = suffixes[number % 10] || 'th';
    return number + suffix;
  }
}
function Commentator() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let broadcast_hex = params.get('broadcast');
    let initialDisplay  = params.get('display') || 'default';
    let thisInnings = params.get('innings') || 1;

    const [matchid, setMatchid] = useState('');;
    const [team1WinPercentage, setTeam1WinPercentage] = useState(null);
    const [team2WinPercentage, setTeam2WinPercentage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(initialDisplay);
    const [Team1Display, setTeam1Display] = useState('');
    const [Team2Display, setTeam2Display] = useState('');
    const [Team1Logo, setTeam1Logo] = useState('');
    const [Team2Logo, setTeam2Logo] = useState('');
    const [PowerBarData, setPowerBarData] = useState([]);
    const [Winner, setWinner] = useState('');
    const [powerbar, setPowerbar] = useState(false);
    const [progressBarColor, setprogressBarColor] = useState('#0581CA');
    const [progressBarColorAlt, setprogressBarColorAlt] = useState('#fff');
    const [bColor, setbColor] = useState('red');
    const [percPresicion, setpercPresicion] = useState(1);
    const [bodyColor, setBodyColor] = useState("#fff");
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [chartData2, setChartData2] = useState(null);
    const [chartOptions2, setChartOptions2] = useState(null);
    const [chartDataRuns, setChartDataRuns] = useState(null);
    const [chartDataRuns2, setChartDataRuns2] = useState(null);
    const [chartOptionsRuns, setChartOptionsRuns] = useState(null);
    const [chartOptionsRuns2, setChartOptionsRuns2] = useState(null);
    const [batting1, setbatting1] = useState(null);
    const [rtnInnings, setrtnInnings] = useState('1');
    const [MatchName, setMatchName] = useState('');
    const [MatchStatus, setMatchStatus] = useState('');
    const [InningsOpts, setInningsOpts] = useState([]);
    //const [ballsPerOver, setballsPerOver] = useState(6);
    //const [overSpace, setoverSpace] = useState(6);
    const [ShowRuns, setShowRuns] = useState(true);
    const [ShowMInns, setShowMInns] = useState(true);
    const [quickName, setquickName] = useState('');
    const endpoint='https://52zqoahdigd5p3z5mx7rw55dta0tvrde.lambda-url.eu-west-2.on.aws/'; //test end point
    //const endpoint='https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/';
    const [gotScoreCard,setGotScoreCard] = useState(false);
    const [scoreline1, setscoreline1] = useState('');
    const [scoreline2, setscoreline2] = useState('');
    const [datapoint1, setdatapoint1] = useState('');
    const [datapoint2, setdatapoint2] = useState('');
    const [scorecardinning, setscorecardinning] = useState('');
    const [batter1, setbatter1] = useState('');
    const [batter2, setbatter2] = useState('');
    const [currentbatting, setcurrentbatting]= useState('');
    const [bowler, setbowler] = useState('');
    const [overballs, setoverballs] = useState('');
    const [predictedruns,setpredictedruns] = useState('');
    const [probpoint, setprobpoint] = useState([0,0,0]);
    const [probChartData, setprobChartData] = useState('');
    const chartRef = useRef(null);
    const chartRef2 = useRef(null);
    let PDruns='';
    const [totalInningsSelect, setTotalInningsSelect] = useState(1);
    
    const fetchData = async (panel) => {
      fetchMatchData(panel);
    };
    const fetchMatchData= async (panel) => {
        try {
            const response = await axios.get(`${endpoint}?action=probabilityprogress&id=${id}&innings=${panel}`,
              {
                headers: {
                  'x-api-key': '4ICgYDw7KLM9Ku2Qc14T0Yi1YbBKE5paH21KP4aUSnewXhQVIQ7f9xixbh1OmEex',
                }
              }
            );
            if (response.status === 200) {
              const responseData = response.data;
              setTeam1Display(responseData.names[0]);
              setTeam2Display(responseData.names[1]);
              setMatchName(responseData.match.name);
              if (responseData.match.status.substr(0,14)=='Match Complete')  {
                setMatchStatus(responseData.match.status);
              }
              if (responseData.match.status.substr(0,14)=='Match Schedule') { 
                const dateObj = new Date(responseData.match.start);
                setMatchStatus(responseData.match_status+" - "+dateObj.toLocaleString('en-GB'));
              }
              let battingColor=responseData.colors.team2;
              if (responseData.match.batting===responseData.names[0]) {
                battingColor=responseData.colors.team1;
              } 
              setprogressBarColor(responseData.colors.team1);
              setprogressBarColorAlt(responseData.colors.team2);
            if (responseData.match.batting==='') {
                setbatting1(" ");
              } else {
                setbatting1(responseData.match.batting);
              }
              setrtnInnings(responseData.match.innings);
              setLoading(false);
              const scoreline=''; //response.data.Scoreline;
              const dataPoints = responseData.probability.team1;
              const dataArray = Object.entries(dataPoints);
              const labels = [];
              let overSpace = 6;
              let pointRadius = 1;
              let current = 1;
              let exp=1;
              let ballsPerOver=6;
              let TotalInnings=response.data.match.total_innings || 1;
              setTotalInningsSelect(TotalInnings);
              let theInnings=[];
              for (var j=1; j<=TotalInnings; j++) {
                theInnings.push({id: j, value: j, label: getOrdinalSuffix(j)+ " Innings"});
              }
              setInningsOpts(theInnings);
              
              const stepSize = 1;
              const chartData = {
               labels: labels,
               datasets: [
                  {
                    label: responseData.names[0],
                    data: dataPoints,
                    fill: false,
                    borderColor: responseData.colors.team1,
                    borderWidth: 3,
                    tension: 0.5,
                    pointRadius: pointRadius,
                  },{
                    label: responseData.names[1],
                    data: responseData.probability.team2,
                    fill: false,
                    borderWidth: 3,
                    borderColor: responseData.colors.team2,
                    tension: 0.5,
                    pointRadius: pointRadius,
                  },{
                    label: 'Draw',
                    data: responseData.probability.draw,
                    borderWidth: 3,
                    borderColor: 'grey',
                    tension: 0.5,
                    pointRadius: pointRadius,
                  }
                ]
              };
              let Wcount = 0;
              let delayed;
              const chartOptions = {
                interaction: {
                  mode: 'x',
                },
                scales: {
                  x: {
                    position: 'top',
                    title: {
                      display: true,
                      text: getOrdinalSuffix(panel)+' Innings',
                      font: {
                        size: 18,
                        weight: 900,
                      }
                    },
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                        if (context.tick) {
                          let tidx=context.index;
                          let bo=dataArray[tidx][0].split(".");
                          if (bo[1]==='1') {
                            return 1;
                          } else {
                            return 0;
                          }
                        }
                      },color: function(context) {
                        return '#cacaca';
                      },
                    },
                    ticks: {
                      maxRotation: 0,
                      autoSkip: false,
                      callback: (value, index) => {
                        let bo=dataArray[index][0].split(".");
                        if (bo[1]==='1') {
                          return bo[0];
                        } else {
                          return '';
                        }
                      }, 
                    },
                  },
                  //wickets
                  x2: {
                    position: 'bottom', 
                    display: true,
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                          if (context.tick) {
                            const ind = context.tick.value;
                            var thisOB=dataArray[ind][0];
                            if (responseData.wickets.includes(Number(thisOB))) {
                              return 3;
                            } else  {
                              return 0;
                            }
                          }
                      },color: function(context) {
                        return 'black';
                      },
                    },
                    ticks: {
                      autoSkip: false,
                      color: 'white',
                      textStrokeWidth: 8,
                      showLabelBackdrop: true,
                      backdropColor: 'black', 
                      backdropPadding: 5,
                      font: { size: 18, weight: 900 },
                      callback: (value, index) => {
                        var thisOB=dataArray[index][0];
                        const wicketIndex = responseData.wickets.indexOf(thisOB) + 1;
                        if (responseData.wickets.includes(Number(thisOB))) {
                          return 'W';
                        } 
                      },
                    },
                  },
                  y: {
                    beginAtZero: true,
                    max: 100,
                    min: 0,
                    ticks: {
                      callback: (value) => `${value}%`
                    },
                    title: {
                      display: true,
                      text: 'Win Prediction',
                      font: {
                        size: 18,
                        weight: 900,
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: "Win Prediction Percentage",
                      fontStyle: 'bold',
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                },
                plugins: {
                  tooltip: {
                    external: handleChart1External, 
                    footerMarginTop: 20,
                    footerSpacing: 4,
                    enabled: false,
                    position: 'nearest',
                    intersect: false,
                    interaction: {
                      mode: 'index'
                    },
                  },
                  legend: {
                    display: false,
                  },
                },
              };
              setChartData(chartData);
              setprobChartData(chartData);
              setChartOptions(chartOptions);
              PDruns=responseData.predictRuns;
              fetchRunData(panel,scoreline,responseData.runs, responseData.wickets,responseData.match,responseData.probability.team2, responseData.predictRuns,battingColor);
            }
        } catch (error) {
            console.error(error);
        }
    };
    let cancelToken;
    const getToolTipCard = async (innings, datapoint, bfaf, predicted) => {
      if (cancelToken) {
        cancelToken.cancel('Cancelled the previous request');
      }
      cancelToken = axios.CancelToken.source();
      try {
        const response = await axios.get(`${endpoint}?action=probabilitytooltip&id=${id}&innings=${innings}&datapoint=${datapoint}&bfaf=${bfaf}`, {
          headers: {
            'x-api-key': '4ICgYDw7KLM9Ku2Qc14T0Yi1YbBKE5paH21KP4aUSnewXhQVIQ7f9xixbh1OmEex',
          },
          cancelToken: cancelToken.token
        });
    
        if (response.status === 200) {
          let dp=datapoint.split(".");
          let over=dp[0];
          let ball=dp[1];
          let SG=response.data.overScoreGrid;

          setscorecardinning(getOrdinalSuffix(innings));
          setscoreline1(response.data.comment);
          setbatter1(response.data.partnership[0]);
          setbatter2(response.data.partnership[1]);
          setcurrentbatting(response.data.batter);
          setbowler(response.data.bowler);
          let xh='';
          for (let b=1; b<=10; b++) {
            if (SG['b'+b]) {
              let curDis="'";
              let currentElement=SG['b'+b];
              if (currentElement!=null) {
                if (currentElement===0) {
                  xh+="<span class='ballDel norun"+curDis+">.</span>";
                } else if (currentElement==='W') {
                  xh+="<span class='ballDel wicket"+curDis+">"+currentElement+"</span>";
                } else {
                  xh+="<span class='ballDel"+curDis+">"+currentElement+"</span>";
                }
              }

            }
          }
          setoverballs( { __html: xh });
          setpredictedruns(parseInt(predicted).toFixed(0));
          setGotScoreCard(true);
        }
      } catch (error) {
        if (axios.isCancel(error)) {
        } else {
          console.error(error);
        }
      }
    };
    const fetchRunData= async (panel,scoreline,runs,wickets,match,probability,runspredict,battingColor) => {
      const runsKeys = Object.keys(runs);
      const dataArray = Object.entries(probability);
      try {
            let targetRuns=[];
            let ballsPerOver=6;
            let overSpace=6;
            let runsPredict=runspredict;
            const cdr = {
              labels: [], 
              datasets: [
                 {
                   label: match.batting + " Batting", 
                   data: runs,
                   fill: true,
                   borderColor: battingColor,
                   borderWidth: 3,
                   tension: 0.5,
                   pointRadius: 1,
                 },
                 {
                  label: "Run target", 
                  data: targetRuns,
                  fill: false,
                  borderColor: 'blue',
                  borderWidth: 1,
                  tension: 0.5,
                  pointRadius: 0,
                 },
                 {
                  label: "predict",
                  data: runsPredict,
                  borderWidth: 1,
                  borderColor: 'green',
                 }
              ]
            };
            const cor = {
              interaction: {
                mode: 'x',
              },
              scales: {
                x: {
                  position: 'bottom',
                  title: {
                    display: true,
                    text: 'Overs',
                    font: {
                      size: 18,
                      weight: 900,
                    }
                  },
                  grid: {
                    drawTicks: true,
                    display: true,
                    drawOnChartArea: true,
                    lineWidth: function(context) {
                      return 2;
                    }
                  },
                  ticks: {
                    maxRotation: 0,
                    autoSkip: false,
                    callback: (value,index) => {
                      let bo=String(runsKeys[index]).split(".");
                      if (bo[1]==='1') {
                        return bo[0];
                      }
                    }
                  },
                },
                //wickets
                x2: {
                  position: 'bottom', 
                  display: true,
                  grid: {
                    drawTicks: false,
                    display: true, 
                    drawOnChartArea: true, 
                    lineWidth: function(context) {
                        if (context.tick) {
                          const ind = context.tick.value;
                          var thisOB=dataArray[ind][0];
                          if (wickets.includes(Number(thisOB))) {
                            return 3;
                          } else  {
                            return 0;
                          }
                        }
                    },color: function(context) {
                      return 'black';
                    },
                  },
                  ticks: {
                    autoSkip: false,
                    color: 'white',
                    textStrokeWidth: 8,
                    showLabelBackdrop: false,
                    backdropColor: 'black', 
                    backdropPadding: 5,
                    font: { size: 18, weight: 900 },
                    callback: (value, index) => {
                      var thisOB=dataArray[index][0];
                      const wicketIndex = wickets.indexOf(thisOB) + 1;
                      if (wickets.includes(Number(thisOB))) {
                        return 'W';
                      } 
                    },
                  },
                },                
                y: {
                  title: {
                    display: true,
                    text: 'Runs',
                    font: {
                      size: 18,
                      weight: 900,
                    },
                    padding: 10
                  },
                },
                responsive: false,
                maintainAspectRatio: false,
              },
              plugins: {
                tooltip: {
                  footerMarginTop: 20,
                  footerSpacing: 4,
                  enabled: false,
                  external: externalTooltipHandler,
                  position: 'nearest',
                  intersect: false,
                  interaction: {
                    mode: 'index'
                  },
                  callbacks: {
                    title: function(context, Team1Display) {
                    },
                    label: function(context) {
                      let label = context.formattedValue+"%";
                      let title = context.chart.tooltip.title;
                      return " "+context.raw+" runs";
                    },
                  }
                },
                legend: {
                  display: false,
                },
              }              
            };

            setChartDataRuns(cdr);
            setChartOptionsRuns(cor);

          //}
      } catch (error) {
        console.log(error);
      }
    };

    const hexToRGB = hex => {
      let alpha = false,
        h = hex.slice(hex.startsWith('#') ? 1 : 0);
      if (h.length === 3) h = [...h].map(x => x + x).join('');
      else if (h.length === 8) alpha = true;
      h = parseInt(h, 16);
      return (
        'rgb' +
        (alpha ? 'a' : '') +
        '(' +
        (h >>> (alpha ? 24 : 16)) +
        ', ' +
        ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
        ', ' +
        ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
        (alpha ? `, ${h & 0x000000ff}` : '') +
        ')'
      );
    };
    const handleInningsChange = (event) => {
      thisInnings=event.target.value;
      setbatting1(null);
      setGotScoreCard(false);
      fetchMatchData(thisInnings);
    };
    const handleScreenCapture = async () => {
      try {
        const targetElement = document.getElementById('ic_fan');
        const canvas = await html2canvas(targetElement);
        const dataURL = canvas.toDataURL();
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = 'Intelligent Cricket - '+quickName+'.png';
        a.click();
      } catch (err) {
        console.error("Error capturing screenshot:", err);
      }
    }
    const handleRefreshData = (event) => {
      setbatting1(null);
      fetchMatchData(1);
    };
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        const table = document.createElement('table');
        table.style.margin = '0px';
        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    };
    let isProgrammaticUpdate = false;
    const handleChart1External = (context) => {
      const {chart, tooltip} = context;
      const datapoint = tooltip.dataPoints[0].label;
      let labelIdx = 0;
      if (chart.scales.x._addedLabels.find(element => element.label == datapoint)) {
        labelIdx=chart.scales.x._addedLabels.find(element => element.label == datapoint);
      }
      const bordercolor = tooltip.dataPoints[0].dataset.borderColor;
      const winpredict= tooltip.dataPoints[0].formattedValue;
      const bordercolor2 = tooltip.dataPoints[1].dataset.borderColor;
      const winpredict2= tooltip.dataPoints[1].formattedValue;
      let probs=[winpredict,winpredict2,0,bordercolor,bordercolor2,'#bababa'];
      setprobpoint(probs);
      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      const tooltipEl = getOrCreateTooltip(chart);
      const overrideY = tooltip.dataPoints[0].element.y;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + (overrideY-7)  + 'px';
      tooltipEl.style.backgroundImage="url(https://cdn.intelligentcricket.com/general-assets/profile/cricketball_solid.png)";
      tooltipEl.style.width = "20px";
      tooltipEl.style.height = "20px";
      tooltipEl.style.backgroundSize="cover";
      tooltipEl.style.backgroundColor="rgba(0,0,0,0)";
      if (isProgrammaticUpdate) {
        return;
      }
      isProgrammaticUpdate = true;
      const chartInstance = chartRef2.current;
      chartInstance.tooltip.setActiveElements([{datasetIndex: 0, index: labelIdx.index}], {x: 0, y: 0});
      chartInstance.update();
      isProgrammaticUpdate = false;
    };
    const externalTooltipHandler = (context) => {
      const {chart, tooltip} = context;
      const datapoint = tooltip.dataPoints[0].label;
      let predicted='';
      let xp = PDruns[datapoint];
      predicted=parseInt(xp).toFixed(0);
      const tooltipEl = getOrCreateTooltip(chart);
      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      const overrideY = tooltip.dataPoints[0].element.y;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + (overrideY-7)  + 'px';
      tooltipEl.style.backgroundImage="url(https://cdn.intelligentcricket.com/general-assets/profile/cricketball_solid.png)";
      tooltipEl.style.width = "20px";
      tooltipEl.style.height = "20px";
      tooltipEl.style.backgroundSize="cover";
      tooltipEl.style.backgroundColor="rgba(0,0,0,0)";
      let labelIdx = chart.scales.x._addedLabels.find(element => element.label == datapoint);
      let p=chart.scales.x._addedLabels.find(element => element.index == parseInt(labelIdx.index)-1);
      let n=chart.scales.x._addedLabels.find(element => element.index == parseInt(labelIdx.index)+1);
      let nlabel, plabel;
      if (n === undefined) { nlabel=null; } else { nlabel=n.label; }
      if (p === undefined) { plabel=null; } else { plabel=p.label; }
      let bfaf=[plabel, nlabel];
      getToolTipCard(rtnInnings,datapoint, bfaf, predicted);
      if (isProgrammaticUpdate) {
        return;
      }
      isProgrammaticUpdate = true;
      const chartInstance = chartRef.current;
      chartInstance.tooltip.setActiveElements([{datasetIndex: 0, index: labelIdx.index},{datasetIndex: 1, index: labelIdx.index}], {x: 0, y: 0});
      chartInstance.update();
      isProgrammaticUpdate = false;
    };
    const handleShowRunsChange = (event) => {
      var selectElement = document.getElementById('chkRuns');
      if (selectElement.checked) {
        setShowRuns(true);
      } else {
        setShowRuns(false);
      }
    }
    const handleMultiInnsChange = (event) => {
      var selectElement = document.getElementById('chkInns');
      if (selectElement.checked) {
        setShowMInns(true);
      } else {
        setShowMInns(false);
      }
    }
    useEffect(() => {
        fetchData(1);
      }, []);

    return (
        <>
        {loading ? (
            <></>
        ) : (
            <>
            <div id='ic_fan'>
              <div id='header' class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-1 col-md-2 col-sm-2'>
                    <img id='logo' class='img-fluid' src='/imgs/IC_logo_sm.png' />
                  </div>
                  <div class='title col-lg-5 col-sm-3 text-start'>WIN PROBABILITY PROGRESSION</div>
                  <div class='matchname col-lg-6 col-sm-7 text-end'><div>{MatchName}</div><div>{MatchStatus}</div></div>
                </div>
              </div>
              <div id='contents' class='container-fluid'>
                <div class='row' style={{borderBottom: "0px"}}>
                  <div className='col-lg-6 col-md-12 mb-3' id='Innswrapper' style={{position: "relative", borderBottom: "0"}}>
                    {chartData ? (
                      <div style={{height:'380px'}}>
                        <Line width='900px' height='380px' ref={chartRef} id='predictHistory' data={chartData} options={chartOptions} />
                      </div>
                    ) : (
                      <div class='cricball'><BiCricketBall /></div>
                    )}
                    {chartDataRuns && ShowRuns ? (    
                      <div style={{height:'380px'}}>
                        <Line width='900px' height='380px' ref={chartRef2} id='runsChart' data={chartDataRuns} options={chartOptionsRuns}/>
                      </div>
                    ) : (
                      <div class='cricball'><BiCricketBall /></div>
                    )}
                  </div>
                  <div className='col-lg-6 col-md-12 mt-3' id='ttip'>
                    <div className='row mb-5'>
                      <div className='col'>
                        <select id='innselect' className='form-control' onChange={handleInningsChange}>
                        {[...Array(totalInningsSelect)].map((_, index) => (
                          <option key={index} value={index + 1}>
                            {getOrdinalSuffix(index + 1)} innings
                          </option>
                        ))}                          
                        </select>
                      </div>
                    </div>
                  {gotScoreCard ? (
                    <>
                    {/* <div className='row'>
                      <div className='col-12 text-center'><h2>{scorecardinning} innings</h2></div>
                    </div> */}
                    <div className='row'>
                      <div className='col-12 text-center'><h2>{scoreline1}</h2></div>
                    </div>
                    <div className='row combo'>
                      <div className='col-6'>
                        <div className='holder'>
                          <img class='icon' src='/imgs/cricketball_black.png' />
                        </div>
                        <div className='name'>{bowler}</div>
                      </div>
                      <div className='col-6'>
                        <div class='holder'>
                          {currentbatting===batter1 ? ( <img class='icon' src='/imgs/cricketbat_black.png' /> ) : ( <></> )}
                        </div>
                        <div className='name'>{batter1}</div>
                        <div style={{clear:'both'}}></div>

                        <div class='holder'>
                          {currentbatting===batter2 ? ( <img class='icon' src='/imgs/cricketbat_black.png' /> ) : ( <></> )}
                        </div>
                        <div className='name'>{batter2}</div>
                      </div>
                    </div>
                    <div className='row balls pt-3 pb-3'>
                      <div className='col text-start'>
                        <div>Deliveries this over:</div>
                        <div dangerouslySetInnerHTML={overballs} />
                      </div>
                    </div>
                    <div className='row prob pb-3'>
                      <div className='col text-start'>
                        <div>Win Probability:</div>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar" style={{width: `${probpoint[0]}%`, backgroundColor: `${probpoint[3]}` }}></div>
                          <div class="progress-bar" role="progressbar" style={{color: '#242424', width: `${probpoint[1]}%`, backgroundColor: `${probpoint[4]}` }}></div>
                        </div>
                        <div className='row'>
                          <div className='col-6'>{probpoint[0]}%</div>
                          <div className='col-6 text-end'>{probpoint[1]}%</div>
                        </div>
                      </div>
                    </div>
                    <div className='row runs pb-3'>
                      <div className='col text-start'>
                        <div>Run Prediction:</div>
                        <div><h3>{predictedruns}</h3></div>
                      </div>
                    </div>
                    </>
                  ) : (
                      <></>  
                  )}
                  </div>
                </div>
              </div>
              <div id='footer' class='container-fluid'>
                  <div class='row powerby' style={{borderColor: bColor}}>
                      <div class='footbtns col-lg-1 col-md-12'>
                       {batting1 ? (
                          <div>
                             <FontAwesomeIcon onClick={handleRefreshData} title='Refresh Data' icon={faSync} />
                             <FontAwesomeIcon onClick={handleScreenCapture} title='Screen Capture' icon={faCamera} />
                          </div>
                        ) : (
                          <div class='batting'>
                            <div class="spinner-border text-light" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class='col-lg-6 col-md-12' id='toolTipInteract'>
                      </div>
                      <div class='competitors col-lg-5 col-md-12 text-end'>
                          <div><span class='teams' style={{backgroundColor: progressBarColor}}>{Team1Display}</span> vs <span class='teams' style={{backgroundColor: progressBarColorAlt}}>{Team2Display}</span></div>
                          <div class='batting'></div>
                      </div>
                  </div>
              </div>
            </div>
            </>
        )}
        </>
    );
}
export default Commentator;