import './App.css';
import React from 'react';
import Embed from './embed';
import Results from './results';
import Reactions from './reactions';
import Predictions from './predictions';
import PredictionHistory from './prediction_history';
import PredictionHistory2 from './prediction_history_2';
import PredictionHistoryCompact from './prediction_history_compact';
import RunPredict from './runpredict';
import Commentator from './commentator';
import Progression from './progression';
import Fan from './fan';
import logo from './IC_logo.png';
import './fonts/Sucrose-Bold.ttf';


function App() {
  const currentUrl = window.location.pathname;
  const domain = window.location.host;
  if (currentUrl.toLowerCase().startsWith('/embed')) {
    return (
      <Embed />
    );
  } else if (currentUrl === '/results') {
    return (
      <Results />
    )
  } else if (currentUrl === '/reactions') {
    return (
      <Reactions />
    )
  } else if (currentUrl === '/predictions') {
    return (
      <Predictions />
    )
  } else if (currentUrl === '/commentator' || domain==='commentator.decimalsports.com') {
    return (
      <Commentator />
    )
  } else if (currentUrl === '/fan' || domain==='fan.decimalsports.com') {
    return (
      <Fan />
    )
  } else if (currentUrl === '/prediction_history') {
    return (
      <PredictionHistory />
    )
  } else if (currentUrl === '/prediction_history_2') {
    return (
      <PredictionHistory2 />
    )
  } else if (currentUrl === '/prediction_history_compact') {
    return (
      <PredictionHistoryCompact />  
    )
  } else if (currentUrl === '/runpredict') {
    return (
      <RunPredict />
    )
  } else if (currentUrl === '/progression') {
    return (
      <Progression />
    )
  }else {
    return (
      <div className='container'>
        <div className='row'>
          <div className='col'>
            <div class="d-flex justify-content-center">
              <img src={logo} className="img-fluid App-logox" alt="Intelligent Cricket" />
            </div>
          </div>'
        </div>
      </div>
    );
  }
};
export default App;
