/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ReactComponent as SquareHalf } from '../node_modules/bootstrap-icons/icons/square-half.svg'
import { ReactComponent as ListCheck } from '../node_modules/bootstrap-icons/icons/card-checklist.svg'
import { ReactComponent as GraphUp } from '../node_modules/bootstrap-icons/icons/graph-up-arrow.svg'
import { ReactComponent as ArrowClockwise } from '../node_modules/bootstrap-icons/icons/arrow-clockwise.svg'
import { ReactComponent as PlusCircle } from '../node_modules/bootstrap-icons/icons/plus-circle.svg'

const blink = "https://broadcast.decimalsports.com/";

function Embed() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let id = params.get('id');
  const [userid, setUserId] = useState('');
  const [data, setData] = useState(null);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const getClientData = async () => {
      try {
        const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getClient&id=${id}`);
        if (response.status === 200) {
          const responseData = response.data;
          setUserId(responseData.uid);
          setData(responseData);
          await getQuestions(response.data.teamid);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getClientData();
  }, [id]);

  const getQuestions = async (teamId) => {
    try {
      const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getQuestions&teamid=${teamId}`);
      if (response.status === 200) {
        setQuestions(response.data.answers);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefreshQuestions = () => {
    getQuestions(data.teamid);
  };

  const [questionOn, setQuestionOn] = useState(false);
  const handleNewQuestion = () => { 
    setQuestionOn(true);
  };
  
  useEffect(() => {
    if (questionOn) {
      const questionTypeSelect = document.getElementById('questionType');
      questionTypeSelect.value = '';
      questionTypeSelect.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, [questionOn]);
  
  const handleCancelQuestion = () => { setQuestionOn(false);};
  const [questionType, setQuestionType] = useState('');
  const handleQuestionTypeChange = (e) => {
    setQuestionType(e.target.value);
  };

  const handleSaveQuestion = async () => {
    const qDetailsSection = document.getElementById('qdetails');
    const formElements = qDetailsSection.querySelectorAll('input, select');
  
    const formInputs = {};
  
    formElements.forEach((element) => {
      const { name, value } = element;
      formInputs[name] = value;
    });
    const headers = {
      'Access-Control-Allow-Origin': '*'
    };
    try {
      const response = await axios.post(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=saveQuestion&teamid=${data.teamid}&userid=${userid}`, 
        formInputs,
        { headers }
        );
        if (response.status === 200) {
          handleQuestionClick(response.data.qid);
          getQuestions(data.teamid);
        }
  
  
    } catch (error) {
      console.error(error);
      alert('Failed to save form inputs.');
    }

  
  };
  
  const handleQuestionClick = async (questionId) => {
    setQuestionOn(true);
    try {
      const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getQuestion&teamid=${data.teamid}&question=${questionId}`);
      if (response.status === 200) {
        const questionType = response.data.answers[0].question_type.trim();
        const questionTypeSelect = document.getElementById('questionType');
        questionTypeSelect.value = questionType;
        questionTypeSelect.dispatchEvent(new Event('change', { bubbles: true }));    
        document.getElementById('question_text').value=response.data.answers[0].question_text;
        document.getElementById('qid').value=response.data.answers[0].id;
        const answers = response.data.answers[0].answers;

        if (questionType==='predict') {
          document.getElementById('broadcast_link').value=blink + response.data.answers[0].broadcast_hex;
          document.getElementById('match_id').value=response.data.answers[0].answers.match_id;

        }






        // Process the response data here
        // console.log(response.data.answers[0].question_type.trim());
      }
    } catch (error) {
      console.error(error);
    }


  };
  
  return (
    <>
      <div className='page-questions container'>
        <div className='row'>
          <div className='col mt-3 mb-3'>
            <div className='tile'>
              <b>TEAM:</b> {data ? data.teamname : ''}
              </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-3'>
            <div className='tile min-vh-75'>
              <div className='row mb-3'>
                <div className='col-9'><b>QUESTIONS:</b></div>
                <div className='col-3 text-end'>
                  <PlusCircle onClick={handleNewQuestion} title='Add New Question' style={{"margin-right":"5px","cursor":"pointer"}} />
                  <ArrowClockwise onClick={handleRefreshQuestions} title='Reload Question List' style={{"cursor":"pointer"}} />
                </div>
              </div>


              {questions.map(question => (
                <div id='questions' className='row'>
                  <div className='col'>
                    <div className="thequestion" key={question.id} onClick={() => handleQuestionClick(question.id)}>
                      <div className='number'>({question.id})</div>
                      <div className='questionname'>{question.question_text}</div>
                      <div className='questiondate text-end'>
                       {question.question_type.trim() === 'yes_no' && <SquareHalf title='Yes/No' alt='Yes/No' />}
                       {question.question_type.trim() === 'multiple' && <ListCheck title='Multiple' alt='Multiple' />}
                       {question.question_type.trim() === 'predict' && <GraphUp title='Predict' alt='Predict' />}
                       {new Date(question.created).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: 'short',
                          year: 'numeric'
                        })}
                      </div>
                      <div className='float-none'></div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>



          {questionOn ? (
            <div className='col-9'>
              <div id='qdetails' className='tile min-vh-75'>
                <div className='row mb-3'>
                  <div className='col'><b>DETAILS:</b></div>
                  <input type='hidden' id='qid' name='qid' value='NEW' />
                </div>
                <div id='questionset' className='p-3'>
                  <div className='row'>
                    <div className='col'>
                      Question Type:
                      <select id='questionType' name='questionType' className='form-control' value={questionType} onChange={handleQuestionTypeChange}>
                        <option></option>
                        <option value='yes_no'>Yes/No</option>
                        <option value='multiple'>Multiple</option>
                        <option value='rating'>Rating</option>
                        <option value='predict'>Live Prediction</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col'>
                      Question Text:
                      <input type='text' className='form-control' id='question_text' name='question_text' />
                    </div>
                  </div>
                  {questionType === 'yes_no' && (
                  <div className='row mt-3'>
                    <div className='col'>
                      Answers Text:
                      <div id='answerBlock'>
                          <input
                            type='text'
                            className='form-control'
                          />
                          <input
                            type='text'
                            className='form-control'
                          />
                      </div>
                    </div>
                  </div>

                  )}

                  {questionType === 'multiple' && (
                    <div></div>

                  )}
                  {questionType === 'predict' && (
                    <>
                    <div className='row mt-3'>
                      <div className='col'>
                        Decimal Match ID:
                        <input
                          id='match_id'
                          name='match_id' 
                          type='text'
                          className='form-control'
                        />
                      </div>
                    </div>
                    <div className='row mt-3'>
                      <div className='col'>
                        Broadcast Graphic Link:
                        <input
                          id='broadcast_link'
                          name='broadcast_link' 
                          type='text'
                          className='form-control'
                          readonly="readonly" 
                        />
                      </div>
                    </div>
                    </>
                  )}


                  <div id='actionbtns' className='row mt-5 text-end'>
                    <div className='col'>
                      <a role='button' className='btn btn-primary ' onClick={handleSaveQuestion} style={{"margin-right":"5px"}} href='#'>Save</a>
                      <a className='btn btn-secondary' href='#' onClick={handleCancelQuestion}>Cancel</a>
                    </div>
                  </div>


                </div>


              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </>
  );
}

export default Embed;
