import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { redirect, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';
import { TwitterShareButton } from 'react-share';
import { BiCricketBall } from "react-icons/bi";
function ttHover(idx) {
  //console.log(idx);





}
function getOrdinalSuffix(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;

  if (remainder >= 11 && remainder <= 13) {
    return number + 'th';
  } else {
    const suffix = suffixes[number % 10] || 'th';
    return number + suffix;
  }
}
function Commentator() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let broadcast_hex = params.get('broadcast');
    let initialDisplay  = params.get('display') || 'default';
    let thisInnings = params.get('innings') || 1;

    const [matchid, setMatchid] = useState('');;
    const [team1WinPercentage, setTeam1WinPercentage] = useState(null);
    const [team2WinPercentage, setTeam2WinPercentage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(initialDisplay);
    const [Team1Display, setTeam1Display] = useState('');
    const [Team2Display, setTeam2Display] = useState('');
    const [Team1Logo, setTeam1Logo] = useState('');
    const [Team2Logo, setTeam2Logo] = useState('');
    const [PowerBarData, setPowerBarData] = useState([]);
    const [Winner, setWinner] = useState('');
    const [powerbar, setPowerbar] = useState(false);
    const [progressBarColor, setprogressBarColor] = useState('#0581CA');
    const [progressBarColorAlt, setprogressBarColorAlt] = useState('#fff');
    const [bColor, setbColor] = useState('red');
    const [percPresicion, setpercPresicion] = useState(1);
    const [bodyColor, setBodyColor] = useState("#fff");
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [chartData2, setChartData2] = useState(null);
    const [chartOptions2, setChartOptions2] = useState(null);
    const [chartDataRuns, setChartDataRuns] = useState(null);
    const [chartDataRuns2, setChartDataRuns2] = useState(null);
    const [chartOptionsRuns, setChartOptionsRuns] = useState(null);
    const [chartOptionsRuns2, setChartOptionsRuns2] = useState(null);
    const [batting1, setbatting1] = useState(null);
    const [rtnInnings, setrtnInnings] = useState('1');
    const [MatchName, setMatchName] = useState('');
    const [MatchStatus, setMatchStatus] = useState('');
    const [InningsOpts, setInningsOpts] = useState([]);
    //const [ballsPerOver, setballsPerOver] = useState(6);
    //const [overSpace, setoverSpace] = useState(6);
    const [ShowRuns, setShowRuns] = useState(true);
    const [ShowMInns, setShowMInns] = useState(true);
    const [quickName, setquickName] = useState('');
    
    const fetchData = async (panel) => {
        if (id && !matchid) {
          try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getBroadcastId&id=${id}`);
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.answers[0].question_type.trim() === 'predict') {
                const newMatchid = responseData.answers[0].answers.match_id.trim();
                setMatchid(newMatchid); 
                setDisplay(responseData.answers[0].answers.graphic.trim());
                setTeam1Display(responseData.answers[0].answers.name_override[0].trim());
                setTeam2Display(responseData.answers[0].answers.name_override[1].trim());
                setquickName(responseData.answers[0].answers.name_override[0].trim()+" vs "+responseData.answers[0].answers.name_override[1].trim());
                setTeam1Logo(responseData.answers[0].answers.logo_override[0].trim());
                setTeam2Logo(responseData.answers[0].answers.logo_override[1].trim());
                setPowerbar(responseData.answers[0].answers.power_bar);
                setbColor(responseData.answers[0].answers.color);
                setprogressBarColor(responseData.answers[0].answers.pb_color);
                const progressBarColorAlt = responseData.answers[0].answers.pb_color_alt || '#fff';
                setprogressBarColorAlt(progressBarColorAlt);
                setpercPresicion(responseData.answers[0].answers.percentage_precision);
                const pageBodyColor = responseData.answers[0].answers.body_background;
                if (pageBodyColor) {
                  //document.body.style.backgroundColor = pageBodyColor;
                }
                fetchMatchData(panel);
              }


            }
          } catch (error) {
            console.error(error);
          }
          setLoading(false);
        }
      };

    const fetchMatchData= async (panel) => {
        try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=powerbarhistory&id=${id}&innings=${panel}`);
            if (response.status === 200) {
              if (response.data.Batting1==='') {
                setbatting1(" ");
              } else {
                setbatting1(response.data.Batting1);
              }
              setrtnInnings(response.data.Innings);
              setMatchName(response.data.MatchName);
              if (response.data.MatchStatus.substr(0,14)=='Match Complete')  {
                setMatchStatus(response.data.MatchStatus);
              }
              if (response.data.MatchStatus.substr(0,14)=='Match Schedule') { 
                const dateObj = new Date(response.data.StartDateTime);
                setMatchStatus(response.data.MatchStatus+" - "+dateObj.toLocaleString('en-GB'));
              }
              const scoreline=response.data.Scoreline;
              const dataPoints = response.data.Team1;
              const labels = [];
              let overSpace = 6;
              let pointRadius = 1;
              let current = 1;
              let exp=1;
              let ballsPerOver=6;
              let TotalInnings=response.data.TotalInnings || 1;
              let theInnings=[];
              for (var j=1; j<=TotalInnings; j++) {
                theInnings.push({id: j, value: j, label: getOrdinalSuffix(j)+ " Innings"});
              }
              setInningsOpts(theInnings);

              for (var i = 0; i < response.data.Wickets.length; i++) {
                response.data.Wickets[i] += 1;
              }
              if (response.data.Team2.length>121) {
                overSpace=30;
                for (let i=0; i < 5; i++) {
                  dataPoints.push(null);
                  response.data.Team2.push(null);
                  response.data.Draw.push(null);
                }
              }
              if (response.data.comp.format===100) {
                ballsPerOver=5;
                overSpace=5;
                for (let i=0; i < 5; i++) {
                  dataPoints.push(null);
                  response.data.Team2.push(null);
                  response.data.Draw.push(null);
                }
              } else if (response.data.comp.format===10) {
                ballsPerOver=6;
                overSpace=6;
                //check ball key
                let actualC=response.data.BallKey[0].split(".");
                response.data.BallKey.forEach(function callback(value, index) {
                  let c=value.split(".");
                  if ((actualC[0]+"/"+actualC[1])!=(c[0]+"/"+c[1])) {
                    dataPoints.splice(index,0,null);
                    response.data.Team2.splice(index,0,null);
                    actualC[1]++;
                    if (actualC[1]>5) {
                      actualC[0]++;
                      actualC[1]=0;
                    }
                  }
                  actualC[1]++;
                  if (actualC[1]>5) {
                    actualC[0]++;
                    actualC[1]=0;
                  }
                });
                for (let i=dataPoints.length; i<=60; i++) {
                  dataPoints.push(null);
                  response.data.Team2.push(null);
                  response.data.Draw.push(null);
                }
              }
              while (current <= (response.data.Team2.length / ballsPerOver)) {
                labels.push(Number(current+"."+exp));
                exp+=1;
                if (exp>ballsPerOver) {
                  current+=1;
                  exp=1;
                }
              }
              const stepSize = 1;
              const chartData = {
               labels: labels,
               datasets: [
                  {
                    label: response.data.Teams.team1,
                    data: dataPoints,
                    fill: false,
                    borderColor: response.data.Colors.team1,
                    borderWidth: 3,
                    tension: 0.5,
                    pointRadius: pointRadius,
                  },{
                    label: response.data.Teams.team2,
                    data: response.data.Team2,
                    fill: false,
                    borderWidth: 3,
                    borderColor: response.data.Colors.team2,
                    tension: 0.5,
                    pointRadius: pointRadius,
                  },{
                    label: 'Draw',
                    data: response.data.Draw,
                    borderWidth: 3,
                    borderColor: 'grey',
                    tension: 0.5,
                    pointRadius: pointRadius,
                  }
                ]
              };
              let Wcount = 0;
              let delayed;
              const chartOptions = {
                interaction: {
                  mode: 'x',
                },
                scales: {
                  x: {
                    position: 'top',
                    title: {
                      display: true,
                      text: getOrdinalSuffix(panel)+' Innings',
                      font: {
                        size: 18,
                        weight: 900,
                      }
                    },
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                        let ballsMatch=ballsPerOver;
                        if (ballsPerOver===5) {
                          ballsMatch=1;
                        }
                        if (context.tick) {
                          const ind = context.tick.value;
                          const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                          if (ball===ballsMatch) {
                            return 1;
                          } else  {
                            return 0;
                          }
                        }
                      },color: function(context) {
                        let ballsMatch=ballsPerOver;
                        if (ballsPerOver===5) {
                          ballsMatch=1;
                        }
                        const ind = context.tick.value;
                        const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                        if (ball===ballsMatch) {
                          return '#cacaca';
                        } 
                      },
                    },
                    ticks: {
                      maxRotation: 0,
                      autoSkip: false,
                      callback: (value, index) => {
                        if (ballsPerOver===5) {
                          if ((index) % overSpace !== 0) {
                            return '';
                          }
                          return ((index) / ballsPerOver);
                        } else {
                          if ((index+1) % overSpace !== 0) {
                            return '';
                          }
                          return ((index+1) / ballsPerOver);
                        }
                      }, 
                    },
                  },
                  x2: {
                    position: 'bottom', 
                    display: true,
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                          if (context.tick) {
                            const ind = context.tick.value;
                            const over = Math.floor((ind+ballsPerOver)/ballsPerOver);
                            const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                            const thisOB = over +(ball/10);
                            if (response.data.Wickets.includes(thisOB)) {
                              return 3;
                            } else  {
                              return 0;
                            }
                          }
                      },color: function(context) {
                        const ind = context.tick.value;
                        const over = Math.floor((ind+ballsPerOver)/ballsPerOver);
                        const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                        const thisOB = over +(ball/10);
                        if (response.data.Wickets.includes(thisOB)) {
                          return 'black';
                        } 
                      },
                    },
                    ticks: {
                      autoSkip: false,
                      color: 'white',
                      textStrokeWidth: 8,
                      showLabelBackdrop: true,
                      backdropColor: 'black', 
                      backdropPadding: 5,
                      font: { size: 18, weight: 900 },
                      callback: (value, index) => {
                        const over = Math.floor((index+ballsPerOver)/ballsPerOver);
                        const ball=((index+ballsPerOver) % ballsPerOver)+1;
                        const thisOB = over +(ball/10);
                        const wicketIndex = response.data.Wickets.indexOf(thisOB) + 1;
                        if (response.data.Wickets.includes(thisOB)) {
                          return 'W';
                        } else {
                          return null;
                        }
                      },
                    },
                  },
                  y: {
                    beginAtZero: true,
                    max: 100,
                    min: 0,
                    ticks: {
                      callback: (value) => `${value}%`
                    },
                    title: {
                      display: true,
                      text: 'Win Prediction',
                      font: {
                        size: 18,
                        weight: 900,
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: "Win Prediction Percentage",
                      fontStyle: 'bold',
                    },
                  },
                  responsive: false,
                  maintainAspectRatio: false,
                },
                plugins: {
                  tooltip: {
                    footerMarginTop: 20,
                    footerSpacing: 4,
                    enabled: true,
                    position: 'nearest',
                    intersect: false,
                    interaction: {
                      mode: 'index'
                    },
                    callbacks: {
                      labelPointStyle: function(context) {
                        return {
                            pointStyle: 'triangle',
                            rotation: 0
                        };
                      },
                      external: function (context) {
                        //console.log("hey");
                      },
                      title: function(context, Team1Display) {
                        let title=context[0].label.split(".");
                        return "Over: "+(parseInt(title[0])-1)+", Ball: "+title[1];
                      },
                      label: function(context) {
                        let label = context.formattedValue+"%";
                        let title = context.chart.tooltip.title;
                        return " "+context.dataset.label+ ": "+label;
                      },
                      footer: function(context) {
                        let title=context[0].label.split(".");
                        let o=(parseInt(title[0])-1);
                        let b=parseInt(title[1]);
                        let idx=(o*ballsPerOver)+b;
                        ttHover(idx);
                        if (scoreline[idx]!==null) {
                          return scoreline[idx];
                        }
                      },
                    }
                  },
                  legend: {
                    display: false,
                  },
                },
              };
              if (panel===1) {
                setChartData(chartData);
                setChartOptions(chartOptions);
              } else {
                setChartData2(chartData);
                setChartOptions2(chartOptions);
              }
              fetchRunData(panel,scoreline);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const fetchRunData= async (panel,scoreline) => {
      try {
          const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=powerbarruns&id=${id}&innings=${panel}`);
          if (response.status === 200) {
            let overScore=response.data.overScore;
            let targetRuns=[];
            let ballsPerOver=6;
            let overSpace=6;
            if (response.data.comp.format===100) {
              ballsPerOver=5;
              overSpace=5;
              //for (let i=0; i < 5; i++) {
              //  dataPoints.push(null);
              //  response.data.Team2.push(null);
              //  response.data.Draw.push(null);
             // }
            }
            let runsPredict=[];
            for (let b=0; b< response.data.BallId.length; b++) {
              for (let i=0; i < response.data.runsPredict.length; i++) {
                let thisb=response.data.runsPredict[i].over+"."+response.data.runsPredict[i].over;
                if (thisb==response.data.BallId[b]) {
                  runsPredict.push(parseInt(response.data.runsPredict[i].line));
                  //break;
                }
              }
            }
            if (panel===2) {
              let target=response.data.targetRuns;
              response.data.Total.forEach((currentElement) => { 
                targetRuns.push(target);
              });
            }
            const cdr = {
              labels: response.data.BallId, 
              datasets: [
                 {
                   label: response.data.Batting + " Batting", 
                   data: response.data.Total,
                   fill: true,
                   borderColor: response.data.BattingColor,
                   borderWidth: 3,
                   tension: 0.5,
                   pointRadius: 1,
                 },
                 {
                  label: "Run target", 
                  data: targetRuns,
                  fill: false,
                  borderColor: response.data.bowlingColor,
                  borderWidth: 1,
                  tension: 0.5,
                  pointRadius: 0,
                 },
                 {
                  label: "predict",
                  data: runsPredict,
                  borderWidth: 1
                 }
              ]
            };
            const cor = {
              interaction: {
                mode: 'x',
              },
              scales: {
                x: {
                  position: 'bottom',
                  title: {
                    display: true,
                    text: 'Overs',
                    font: {
                      size: 18,
                      weight: 900,
                    }
                  },
                  grid: {
                    drawTicks: true,
                    display: true,
                    drawOnChartArea: true,
                    lineWidth: function(context) {
                      if (response.data.overBreak.indexOf(context.index)>=0) {
                        return 3;
                      } else {
                        return 0;
                      }
                    }
                  },
                  ticks: {
                    maxRotation: 0,
                    autoSkip: false,
                    callback: (value,index) => {
                      if (response.data.overBreak.indexOf(index)>=0) {
                        return response.data.overBreak.indexOf(index)+1; //((index+1) / ballsPerOver);
                      } else {
                        return "";
                      }
                    }
                  },
                },
                y: {
                  title: {
                    display: true,
                    text: 'Runs',
                    font: {
                      size: 18,
                      weight: 900,
                    }
                  },
                },
                responsive: false,
                maintainAspectRatio: false,
              },
              plugins: {
                tooltip: {
                  footerMarginTop: 20,
                  footerSpacing: 4,
                  enabled: false,
                  external: externalTooltipHandler,
                  position: 'nearest',
                  intersect: false,
                  interaction: {
                    mode: 'index'
                  },
                  callbacks: {
                    title: function(context, Team1Display) {
                      //let title=context[0].label.split(".");
                      //return "Over: "+(parseInt(title[0]))+", Ball: "+title[1];
                    },
                    label: function(context) {
                      //let label = context.formattedValue+"%";
                      //let title = context.chart.tooltip.title;
                      //return " "+context.raw+" runs";
                    },
                    footer: function(context) {
                      let title=context[0].label.split(".");
                      let o=(parseInt(title[0]));
                      let b=parseInt(title[1]);
                      let idx=(o*ballsPerOver)+b;
                      var xh="<div class='row'>";
                      xh+="<div class='col-3'><h3>Innings: "+response.data.Innings+" , Over: "+(o+1)+", Ball: "+b+"</h3>"; 
                      xh+="Total Runs: "+context[0].raw+"<br/>"+response.data.Batting+" batting</div>";
                      xh+="<div class='col-3'>";
                      xh+="<img class='icon' src='/imgs/cricketball.png' /><div class='player'>"+overScore[o].bowler+"</div>";
                      xh+="<div style='clear:both;'></div>";
                      var batter=response.data.batters[((o)+((b)/10))];
                      var partner='';
                      if (typeof batter==='undefined') { batter=''; }
                      if (typeof response.data.batPartnerships[((o)+((b)/10))]!='undefined') {
                        partner=response.data.batPartnerships[((o)+((b)/10))][0];
                        if (partner===batter) { partner=response.data.batPartnerships[((o)+((b)/10))][1]; }
                      }
                      if (typeof partner==='undefined') { partner=''; }
                      xh+="<img class='icon' src='/imgs/cricketbat.png' /><div class='player'>"+batter+"</div>";
                      xh+="<div style='clear:both;'></div>";
                      xh+="<div class='partner'>"+partner+"</div>";
                      xh+="</div>";
                      xh+="<div class='col-6'>"
                      overScore[o].ballDel.forEach((currentElement, idx) => { 
                        let curDis="'";
                        if (idx===b) {
                          curDis=" currentBall' style='background-color:"+response.data.BattingColor+";color:#fff;'";
                        }
                        if (currentElement!=null) {
                          if (currentElement==='0') {
                            xh+="<span class='ballDel norun"+curDis+">.</span>";
                          } else if (currentElement==='W') {
                            xh+="<span class='ballDel wicket"+curDis+">"+currentElement+"</span>";
                          } else {
                            xh+="<span class='ballDel"+curDis+">"+currentElement+"</span>";
                          }
                        } 
                      });
                      xh+="</div></div>";
                      return xh;
                    },
                  }
                },
                legend: {
                  display: false,
                },
              }              
            };

            if (panel===1) {
              setChartDataRuns(cdr);
              setChartOptionsRuns(cor);
            } else {
              setChartDataRuns2(cdr);
              setChartOptionsRuns2(cor);
            }

          }
      } catch (error) {
        console.log(error);
      }
    };

    const hexToRGB = hex => {
      let alpha = false,
        h = hex.slice(hex.startsWith('#') ? 1 : 0);
      if (h.length === 3) h = [...h].map(x => x + x).join('');
      else if (h.length === 8) alpha = true;
      h = parseInt(h, 16);
      return (
        'rgb' +
        (alpha ? 'a' : '') +
        '(' +
        (h >>> (alpha ? 24 : 16)) +
        ', ' +
        ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
        ', ' +
        ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
        (alpha ? `, ${h & 0x000000ff}` : '') +
        ')'
      );
    };
    const handleInningsChange = (event) => {
      thisInnings=event.target.value;
      setbatting1(null);
      fetchMatchData();
    };
    const handleScreenCapture = async () => {
      try {
        const targetElement = document.getElementById('ic_fan');
        const canvas = await html2canvas(targetElement);
        const dataURL = canvas.toDataURL();
        const a = document.createElement('a');
        a.href = dataURL;
        a.download = 'Intelligent Cricket - '+quickName+'.png';
        a.click();
      } catch (err) {
        console.error("Error capturing screenshot:", err);
      }
    }
    const handleRefreshData = (event) => {
      setbatting1(null);
      fetchMatchData(1);
      fetchMatchData(2);
    };
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
        tooltipEl.style.borderRadius = '3px';
        tooltipEl.style.color = 'white';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .1s ease';
        const table = document.createElement('table');
        table.style.margin = '0px';
        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }
      return tooltipEl;
    };
    const externalTooltipHandler = (context) => {
      const {chart, tooltip} = context;
      const tooltipEl = getOrCreateTooltip(chart);
      const datapoint = tooltip.dataPoints[0].label;
      const overrideY = tooltip.dataPoints[0].element.y;
      var innerHtml=tooltip.footer[0];
      var selectElement= document.getElementById('toolTipInteract');
      selectElement.innerHTML=innerHtml;
      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      //tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.top = positionY + (overrideY-7)  + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
      tooltipEl.style.backgroundImage="url(https://cdn.intelligentcricket.com/general-assets/profile/cricketball_solid.png)";
      tooltipEl.style.width = "15px";
      tooltipEl.style.height = "15px";
      tooltipEl.style.backgroundSize="cover";
      tooltipEl.style.backgroundColor="rgba(0,0,0,0)";




      //let LinkedChart = Chart.getChart("predictHistory");
      //const tooltipE2 = getOrCreateTooltip(LinkedChart);
      //console.log(LinkedChart.tooltip.getActiveElements());

      
      //LinkedChart.tooltip.setActiveElements([
      //  { datasetIndex: 0, index: 0, eventPosition: {x: 0, y:0}  }
      //]);
      //LinkedChart.update();
      

      //const ctx = document.getElementById('predictHistory').getContext('2d');
      //const myChart = new Chart(ctx,{});



    };
    const handleShowRunsChange = (event) => {
      var selectElement = document.getElementById('chkRuns');
      if (selectElement.checked) {
        setShowRuns(true);
      } else {
        setShowRuns(false);
      }
    }
    const handleMultiInnsChange = (event) => {
      var selectElement = document.getElementById('chkInns');
      if (selectElement.checked) {
        setShowMInns(true);
      } else {
        setShowMInns(false);
      }
    }
    useEffect(() => {
        fetchData(1);
        fetchData(2);
      }, []);

    return (
        <>
        {loading ? (
            <></>
        ) : (
            <>
            <div id='ic_fan'>
              <div id='header' class='container-fluid'>
                <div class='row'>
                  <div class='col-lg-1 col-md-2 col-sm-2'>
                    <img id='logo' class='img-fluid' src='/imgs/IC_logo_sm.png' />
                  </div>
                  <div class='title col-lg-5 col-sm-3 text-start'>WIN PROBABILITY PROGRESSION</div>
                  <div class='matchname col-lg-6 col-sm-7 text-end'><div>{MatchName}</div><div>{MatchStatus}</div></div>
                </div>
              </div>
              <div id='contents' class='container-fluid'>
                <div class='row' style={{borderBottom: "0px"}}>
                  <div className='col-lg-6 col-md-12 mb-3' id='Innswrapper' style={{position: "relative", maxHeight: "85vh", borderBottom: "0"}}>
                    {chartData ? (
                      <div>
                        <Line id='predictHistory' data={chartData} options={chartOptions} />
                      </div>
                    ) : (
                      <div class='cricball'><BiCricketBall /></div>
                    )}
                    {chartDataRuns && ShowRuns ? (    
                      <div>
                        <Line id='runsChart' data={chartDataRuns} options={chartOptionsRuns}/>
                      </div>
                    ) : (
                      <div class='cricball'><BiCricketBall /></div>
                    )}
                  </div>
                  <div class='col-lg-6 col-md-12'>
                    {chartData2 ? (
                      <div>
                        <Line id='predictHistory2' data={chartData2} options={chartOptions2} />
                      </div>
                    ) : (
                      <div class='cricball'><BiCricketBall /></div>
                    )}
                    {chartDataRuns2 ? (
                      <div>
                        <Line id='runsChart2' data={chartDataRuns2} options={chartOptionsRuns2}/>
                      </div>
                    ) : ( 
                      <div class='cricball'><BiCricketBall /></div>
                    )}
                  </div> 
                </div>
              </div>
              <div id='footer' class='container-fluid'>
                  <div class='row powerby' style={{borderColor: bColor}}>
                      <div class='footbtns col-lg-1 col-md-12'>
                       {batting1 ? (
                          <div>
                             <FontAwesomeIcon onClick={handleRefreshData} title='Refresh Data' icon={faSync} />
                             <FontAwesomeIcon onClick={handleScreenCapture} title='Screen Capture' icon={faCamera} />
                          </div>
                        ) : (
                          <div class='batting'>
                            <div class="spinner-border text-light" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div class='col-lg-6 col-md-12' id='toolTipInteract'>
                      </div>
                      <div class='competitors col-lg-5 col-md-12 text-end'>
                          <div><span class='teams' style={{backgroundColor: progressBarColor}}>{Team1Display}</span> vs <span class='teams' style={{backgroundColor: progressBarColorAlt}}>{Team2Display}</span></div>
                          <div class='batting'></div>
                      </div>
                  </div>
              </div>
            </div>
            </>
        )}
        </>
    );
      

}

export default Commentator;