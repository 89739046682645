import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { redirect, useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { Interaction } from 'chart.js';
import { getRelativePosition } from 'chart.js/helpers';
import { faCropSimple } from '@fortawesome/free-solid-svg-icons';

function getOrdinalSuffix(number) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const remainder = number % 100;

  if (remainder >= 11 && remainder <= 13) {
    return number + 'th';
  } else {
    const suffix = suffixes[number % 10] || 'th';
    return number + suffix;
  }
}
function PredictionHistory() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('id');
    let broadcast_hex = params.get('broadcast');
    let initialDisplay  = params.get('display') || 'default';
    let thisInnings = params.get('innings') || 1;

    const [matchid, setMatchid] = useState('');;
    const [team1WinPercentage, setTeam1WinPercentage] = useState(null);
    const [team2WinPercentage, setTeam2WinPercentage] = useState(null);
    const [loading, setLoading] = useState(true);
    const [display, setDisplay] = useState(initialDisplay);
    const [Team1Display, setTeam1Display] = useState('');
    const [Team2Display, setTeam2Display] = useState('');
    const [Team1Logo, setTeam1Logo] = useState('');
    const [Team2Logo, setTeam2Logo] = useState('');
    const [PowerBarData, setPowerBarData] = useState([]);
    const [Winner, setWinner] = useState('');
    const [powerbar, setPowerbar] = useState(false);
    const [progressBarColor, setprogressBarColor] = useState('#0581CA');
    const [progressBarColorAlt, setprogressBarColorAlt] = useState('#fff');
    const [bColor, setbColor] = useState('red');
    const [percPresicion, setpercPresicion] = useState(1);
    const [bodyColor, setBodyColor] = useState("#fff");
    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);
    const [batting1, setbatting1] = useState(null);
    const [rtnInnings, setrtnInnings] = useState('1');
    
    const fetchData = async () => {
        if (id && !matchid) {
          try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getBroadcastId&id=${id}`);
            if (response.status === 200) {
              const responseData = response.data;
              if (responseData.answers[0].question_type.trim() === 'predict') {
                const newMatchid = responseData.answers[0].answers.match_id.trim();
                setMatchid(newMatchid); 
                setDisplay(responseData.answers[0].answers.graphic.trim());
                setTeam1Display(responseData.answers[0].answers.name_override[0].trim());
                setTeam2Display(responseData.answers[0].answers.name_override[1].trim());
                setTeam1Logo(responseData.answers[0].answers.logo_override[0].trim());
                setTeam2Logo(responseData.answers[0].answers.logo_override[1].trim());
                setPowerbar(responseData.answers[0].answers.power_bar);
                setbColor(responseData.answers[0].answers.color);
                setprogressBarColor(responseData.answers[0].answers.pb_color);
                const progressBarColorAlt = responseData.answers[0].answers.pb_color_alt || '#fff';
                setprogressBarColorAlt(progressBarColorAlt);
                setpercPresicion(responseData.answers[0].answers.percentage_precision);
                const pageBodyColor = responseData.answers[0].answers.body_background;
                if (pageBodyColor) {
                  document.body.style.backgroundColor = pageBodyColor;
                }
                fetchMatchData();
              }


            }
          } catch (error) {
            console.error(error);
          }
          setLoading(false);
        }
      };

    const fetchMatchData= async () => {
        try {
            const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=powerbarhistory&id=${id}&innings=${thisInnings}`);
            if (response.status === 200) {
              setbatting1(response.data.Batting1);
              setrtnInnings(response.data.Innings);
              const scoreline=response.data.Scoreline;
              const dataPoints = response.data.Team1;
              const labels = [];
              let overSpace = 6;
              let pointRadius = 1;
              let current = 1;
              let exp=1;
              let ballsPerOver=6;

              for (var i = 0; i < response.data.Wickets.length; i++) {
                response.data.Wickets[i] += 1;
              }
              if (response.data.Team2.length>121) {
                overSpace=30;
                for (let i=0; i < 6; i++) {
                  dataPoints.push(null);
                  response.data.Team2.push(null);
                  response.data.Draw.push(null);
                }
              }
              if (response.data.comp.format===100) {
                ballsPerOver=5;
                overSpace=5;
                for (let i=0; i < 4; i++) {
                  dataPoints.push(null);
                  response.data.Team2.push(null);
                  response.data.Draw.push(null);
                }
              }
              while (current <= (response.data.Team2.length / ballsPerOver)) {
                labels.push(Number(current+"."+exp));
                exp+=1;
                if (exp>ballsPerOver) {
                  current+=1;
                  exp=1;
                }
              }
              const stepSize = 1;
              const chartData = {
               labels: labels,
               datasets: [
                  {
                    label: response.data.Teams.team1,
                    data: dataPoints,
                    fill: false,
                    borderColor: response.data.Colors.team1,
                    borderWidth: 3,
                    tension: 0.5,
                    pointRadius: pointRadius,
                  },{
                    label: response.data.Teams.team2,
                    data: response.data.Team2,
                    fill: false,
                    borderWidth: 3,
                    borderColor: response.data.Colors.team2,
                    tension: 0.5,
                    pointRadius: pointRadius,
                  },{
                    label: 'Draw',
                    data: response.data.Draw,
                    borderWidth: 3,
                    borderColor: 'grey',
                    tension: 0.5,
                    pointRadius: pointRadius,
                  }
                ]
              };
              let Wcount = 0;
              let delayed;
              const chartOptions = {
                interaction: {
                  mode: 'x',
                },
                scales: {
                  x: {
                    position: 'top',
                    title: {
                      display: true,
                      text: 'Overs',
                      font: {
                        size: 18,
                        weight: 900,
                      }
                    },
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                        let ballsMatch=ballsPerOver;
                        if (ballsPerOver===5) {
                          ballsMatch=1;
                        }
                        if (context.tick) {
                          const ind = context.tick.value;
                          const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                          if (ball===ballsMatch) {
                            return 1;
                          } else  {
                            return 0;
                          }
                        }
                      },color: function(context) {
                        let ballsMatch=ballsPerOver;
                        if (ballsPerOver===5) {
                          ballsMatch=1;
                        }
                        const ind = context.tick.value;
                        const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                        if (ball===ballsMatch) {
                          return '#cacaca';
                        } 
                      },
                    },
                    ticks: {
                      maxRotation: 0,
                      autoSkip: false,
                      callback: (value, index) => {
                        if (ballsPerOver===5) {
                          if ((index) % overSpace !== 0) {
                            return '';
                          }
                          return ((index) / ballsPerOver);
                        } else {
                          if ((index+1) % overSpace !== 0) {
                            return '';
                          }
                          return ((index+1) / ballsPerOver);
                        }
                      }, 
                    },
                  },
                  x2: {
                    position: 'bottom', 
                    display: true,
                    grid: {
                      drawTicks: true,
                      display: true, 
                      drawOnChartArea: true, 
                      lineWidth: function(context) {
                          if (context.tick) {
                            const ind = context.tick.value;
                            const over = Math.floor((ind+ballsPerOver)/ballsPerOver);
                            const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                            const thisOB = over +(ball/10);
                            if (response.data.Wickets.includes(thisOB)) {
                              return 3;
                            } else  {
                              return 0;
                            }
                          }
                      },color: function(context) {
                        const ind = context.tick.value;
                        const over = Math.floor((ind+ballsPerOver)/ballsPerOver);
                        const ball=((ind+ballsPerOver) % ballsPerOver)+1;
                        const thisOB = over +(ball/10);
                        if (response.data.Wickets.includes(thisOB)) {
                          return 'black';
                        } 
                      },
                    },
                    ticks: {
                      autoSkip: false,
                      color: 'white',
                      textStrokeWidth: 8,
                      showLabelBackdrop: true,
                      backdropColor: 'black', 
                      backdropPadding: 5,
                      font: { size: 18, weight: 900 },
                      callback: (value, index) => {
                        const over = Math.floor((index+ballsPerOver)/ballsPerOver);
                        const ball=((index+ballsPerOver) % ballsPerOver)+1;
                        const thisOB = over +(ball/10);
                        const wicketIndex = response.data.Wickets.indexOf(thisOB) + 1;
                        if (response.data.Wickets.includes(thisOB)) {
                          return 'W';
                        } else {
                          return null;
                        }
                      },
                    },
                  },
                  y: {
                    beginAtZero: true,
                    max: 100,
                    min: 0,
                    ticks: {
                      callback: (value) => `${value}%`
                    },
                    title: {
                      display: true,
                      text: 'Win Prediction',
                      font: {
                        size: 18,
                        weight: 900,
                      }
                    },
                    scaleLabel: {
                      display: true,
                      labelString: "Win Prediction Percentage",
                      fontStyle: 'bold',
                    },
                  },
                  responsive: true,
                },
                plugins: {
                  tooltip: {
                    footerMarginTop: 20,
                    footerSpacing: 4,
                    enabled: true,
                    position: 'nearest',
                    intersect: false,
                    interaction: {
                      mode: 'index'
                    },
                    callbacks: {
                      labelPointStyle: function(context) {
                        return {
                            pointStyle: 'triangle',
                            rotation: 0
                        };
                      },
                      title: function(context, Team1Display) {
                        let title=context[0].label.split(".");
                        return "Over: "+(parseInt(title[0])-1)+", Ball: "+title[1];
                      },
                      label: function(context) {
                        let label = context.formattedValue+"%";
                        let title = context.chart.tooltip.title;
                        //console.log(context);
                        return " "+context.dataset.label+ ": "+label;
                      },
                      footer: function(context) {
                        let title=context[0].label.split(".");
                        let o=(parseInt(title[0])-1);
                        let b=parseInt(title[1]);
                        let idx=(o*6)+b;
                        if (scoreline[idx]!==null) {
                          return scoreline[idx];
                        }
                      },
                    }
                  },
                  legend: {
                    display: false,
                  },

                },
              };
        
              setChartData(chartData);
              setChartOptions(chartOptions);


            }
        } catch (error) {
            console.error(error);
        }
    };

    const hexToRGB = hex => {
      let alpha = false,
        h = hex.slice(hex.startsWith('#') ? 1 : 0);
      if (h.length === 3) h = [...h].map(x => x + x).join('');
      else if (h.length === 8) alpha = true;
      h = parseInt(h, 16);
      return (
        'rgb' +
        (alpha ? 'a' : '') +
        '(' +
        (h >>> (alpha ? 24 : 16)) +
        ', ' +
        ((h & (alpha ? 0x00ff0000 : 0x00ff00)) >>> (alpha ? 16 : 8)) +
        ', ' +
        ((h & (alpha ? 0x0000ff00 : 0x0000ff)) >>> (alpha ? 8 : 0)) +
        (alpha ? `, ${h & 0x000000ff}` : '') +
        ')'
      );
    };

    useEffect(() => {
        fetchData();
      }, []);


    return (
        <>
        {loading ? (
            <></>
        ) : (
            <>
            <div id='header' class='container'>
              <div class='row' style={{borderColor: bColor, backgroundColor: bColor }}>
                <div class='col'>WIN PREDICT PROGRESSION</div>
              </div>
            </div>
            <div id='contents' class='container'>
              <div class='row'  style={{borderColor: bColor}}>
                <div class='col' id='wrapper'>
                {chartData ? (
                <Line data={chartData} options={chartOptions}/>
              ) : (
                <div>Loading chart...</div>
              )}
                </div>
               </div>
            </div>


            <div id='footer' class='container'>
                <div class='row powerby' style={{borderColor: bColor}}>
                    <div class='col-2 powerby'>
                        <img class='img-fluid' src='/imgs/IC_logo_sm.png' />
                    </div>
                    <div class='col-10 text-end'>
                        <div><span class='teams' style={{backgroundColor: progressBarColor}}>{Team1Display}</span> vs <span class='teams' style={{backgroundColor: progressBarColorAlt}}>{Team2Display}</span></div>
                        <div class='batting'>({getOrdinalSuffix(rtnInnings)} Innings - {batting1} Batting)</div>
                    </div>
                </div>
            </div>
            </>
        )}
        </>
    );
      

}

export default PredictionHistory;