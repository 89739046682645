import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import './App.css';

function Predictions() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let matchid = params.get('matchid');
  let id = params.get('id');
  let broadcast_hex = params.get('broadcast');
  let initialDisplay  = params.get('display') || 'default';
  let widgetwidth = params.get('width') || '100%';
  let widgetbackground = params.get('background');
  let widgetborder = params.get('border');
  let widgetsponsor = params.get('sponsor') || '';
  let widgetanimate = params.get('animate') || '';

  const [team1WinPercentage, setTeam1WinPercentage] = useState(null);
  const [team2WinPercentage, setTeam2WinPercentage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState(initialDisplay);
  const [Team1Display, setTeam1Display] = useState('');
  const [Team2Display, setTeam2Display] = useState('');
  const [Team1Logo, setTeam1Logo] = useState('');
  const [Team2Logo, setTeam2Logo] = useState('');
  const [PowerBarData, setPowerBarData] = useState([]);
  const [Winner, setWinner] = useState('');
  const [powerbar, setPowerbar] = useState(false);
  const [progressBarColor, setprogressBarColor] = useState('#0581CA');
  const [progressBarColorAlt, setprogressBarColorAlt] = useState('#fff');
  const [bColor, setbColor] = useState('red');
  const percPresicion = useRef(1);
  const [bodyColor, setBodyColor] = useState("#fff");
  const [drawDisplay, setdrawDisplay] = useState(false);
  const [showFlags, setshowFlags] = useState(false);

  const fetchData = async () => {
    if (id) {
      try {
        const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=getBroadcastId&id=${id}`);
        if (response.status === 200) {
          const responseData = response.data;
          if (responseData.answers[0].question_type.trim()==='predict') {
            matchid=responseData.answers[0].answers.match_id.trim();
            setDisplay(responseData.answers[0].answers.graphic.trim());
            setTeam1Display(responseData.answers[0].answers.name_override[0].trim());
            setTeam2Display(responseData.answers[0].answers.name_override[1].trim());       
            setTeam1Logo(responseData.answers[0].answers.logo_override[0].trim());
            setTeam2Logo(responseData.answers[0].answers.logo_override[1].trim());
            setPowerbar(responseData.answers[0].answers.power_bar);
            setbColor(responseData.answers[0].answers.color);
            setprogressBarColor(responseData.answers[0].answers.pb_color);
            setdrawDisplay(responseData.answers[0].answers.draw);
            setshowFlags(responseData.answers[0].answers.show_flags);
            const progressBarColorAlt = responseData.answers[0].answers.pb_color_alt || '#fff';
            setprogressBarColorAlt(progressBarColorAlt);
            percPresicion.ref=responseData.answers[0].answers.percentage_precision;
            //setpercPresicion(responseData.answers[0].answers.percentage_precision);
            const pageBodyColor = responseData.answers[0].answers.body_background;
            if (pageBodyColor) {
              document.body.style.backgroundColor = pageBodyColor;
            }

            //console.log(responseData.answers[0].answers.body_background);
            //fetchPowerBarData();
          }
        }
      } catch (error) {
        console.error(error);
      }

    } else {
      alert("x");
      fetchMatchData();
    }
    fetchPowerBarData();
    setLoading(false);
  };
  const fetchMatchData= async () => {
    try {
      const response = await axios.post(
        `https://api.slogsix.com/history`,
        {
          "matchid": matchid,
          "message_id": 1
        },
        {
          headers: {
            apikey: '4ICgYDw7KLM9Ku2Qc14T0Yi1YbBKE5paH21KP4aUSnewXhQVIQ7f9xixbh1OmEex',
          },
        }
      );
      setTeam1Display(response.data.payload.teams[0].displayName);
      setTeam2Display(response.data.payload.teams[1].displayName);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [matchid]);

  useEffect(() => {
    const interval = setInterval(fetchPowerBarData, 5000)
    return () => clearInterval(interval)
  }, [matchid]);

  const fetchPowerBarData = async () => {
    let percentageOne, percentageTwo, drawPercentage;
    try {
      const config = {
        headers: {
          'referer': 'decimalsports.com'
        }
      };
      const response = await axios.get(`https://ixmcuvg4lmi2h736lawe7dabhq0spxpn.lambda-url.eu-west-2.on.aws/?action=powerbar&match_id=${matchid}`, config);
      setPowerBarData(response.data);
      if (percPresicion.ref==1) {
        percentageOne = ((response.data.historical_powerbar[0].msg_data.team1winpercentage*100).toFixed(1));
        percentageTwo = ((response.data.historical_powerbar[0].msg_data.team2winpercentage*100).toFixed(1));
      } else {
        percentageOne = ((response.data.historical_powerbar[0].msg_data.team1winpercentage*100).toFixed(0));
        drawPercentage = ((response.data.historical_powerbar[0].msg_data.drawpercentage*100).toFixed(0));
        if (response.data.historical_powerbar[0].msg_data.team1winpercentage!==.99 && response.data.historical_powerbar[0].msg_data.team2winpercentage!=.99) {
          percentageTwo = (100-percentageOne-drawPercentage);
        } else {
          percentageTwo = ((response.data.historical_powerbar[0].msg_data.team2winpercentage*100).toFixed(0));
        }
      }
      if (response.data.historical_powerbar[0].msg_data.winner==='Northamptonshire') {
        setWinner('Steelbacks');
      } else {
        setWinner(response.data.historical_powerbar[0].msg_data.winner);
        //get winner status
      }
      setTeam1WinPercentage(percentageOne);
      setTeam2WinPercentage(percentageTwo);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchWinnerStatus = async () => {


  };
  useEffect(() => {
    if (matchid) {
      fetchPowerBarData();
    }
  }, [matchid]);


  return (
    <>
      {loading ? (
        <div></div>
      ) : display === 'default' ? (
      <div id='broadcasting'>
        <div className="container">
          <div className="row">
              <div className="col-12 pt-4">
                  <img src='/imgs/onscreen_graphic_blue_top.png' className='img-fluid' />
              </div>
          </div>
          <div className="row g-0 graphic-contents">
              <div className="col-5">
                  <div className='contents'>
                      <div class="progress progress-bar-vertical">
                          <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{"height": `${team2WinPercentage}%`}}>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="col-7">
                  <div class='contents'>
                      <div className='row'>
                          {/* <div className='col-6'>
                              <img src='/imgs/SteelbacksTicket-100x100.png.webp' className='img-fluid' /><br/>
                          </div> */}
                          <div className='col-12'>
                              <div class='result-text'>{Team1Display}</div>
                              <div class='result-current'>{team1WinPercentage}%</div>
                          </div>    
                      </div>
                      <div className='row mt-3 mb-3'>
                        <div className='col text-start'>v</div>
                      </div>
                      <div className='row'>
                          {/* <div className='col-6'>
                              <img src='/imgs/WorcestershireWB-65x65.png.webp' className='img-fluid' />
                          </div> */}
                          <div className='col-12'>
                              <div class='result-text'>{Team2Display}</div>
                              <div class='result-current'>{team2WinPercentage}%</div>
                          </div>    
                      </div>
                  </div>
              </div>
              <div className='col-12'>
                  <div class='contents text-center pt-5'>
                      <span class='title'>PREDICTION:</span><br/>
                      Who will win?
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-12">
                  <img src='/imgs/onscreen_graphic_blue_btm.png' className='img-fluid' />
              </div>
          </div>
        </div>
      </div>
     ) : display === 'nccc' ? (
          <div class='container-fluid'>
            <div id='broadcasting_nccc'>
            <div class='row'>
              <div class='col-12'>
                <div class='wrapper'>
                  <div class='row'>
                    <div class='col'>
                      {Winner !== '' && Winner !=='NA' ? 
                      <h2>{Winner} Wins!</h2>
                      :
                      <h2>Win Prediction</h2>}
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-2 text-end percent'>
                      {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team1WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                      '' : team1WinPercentage+'%' }
                      </div>
                    <div class='col-2 text-end dis'>
                      <b>{Team1Display}</b>
                    </div>
                    <div class='col-4 text-center'><img class='logoicon' src={Team1Logo} /> <span class='vs'>vs</span> <img class='logoicon' src={Team2Logo} /></div>
                    <div class='col-2 text-start dis'>
                      <b>{Team2Display}</b>
                    </div>
                    <div class='col-2 text-start percent'>
                      {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team2WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                      '' : team2WinPercentage+'%' }
                    </div>
                  </div>
                  <div class='row mb-1'>


                  </div>
                  <div class='row'>
                    <div class='col'>
                      <hr />
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col text-end powerby'>
                      Powered by&nbsp; <img src='/imgs/IC_logo_sm.png' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>

    ) : display === 'nccc_2' ? (
      <div id='nccc_2'>
        <div class='container'>
          <div class='row'>
            <div class='col-6 left'>
              <div class='team'>
                {Team1Display}
              </div>
              <div class='percent'>
                {team1WinPercentage==='99' && team2WinPercentage==='99' || team1WinPercentage===null ?
                      '' : team1WinPercentage+'%' }
              </div>
            </div>
            <div class='col-6 right'>
              <div class='team'>
                {Team2Display}
              </div>
              <div class='percent'>
                {team1WinPercentage==='99' && team2WinPercentage==='99' || team2WinPercentage===null ?
                      '' : team2WinPercentage+'%' }
              </div>
            </div>
          </div>
          {powerbar===true ? (
          <>
          <div class='row powerbar'>
            <div class='col-1 text-end'>
              <img class='img-fluid' src={Team1Logo} /> 
            </div>
            <div class='col-11 chart'>
            </div>


          </div>
          <div class='row powerbar'>
            <div class='col-1 text-end'>
              <img class='img-fluid' src={Team2Logo} /> 
            </div>
          </div>
          </>

          ) : ( <div></div> )
          }

          <div class='row'>
            <div class='col text-center powerby'>
              <img class='img-fluid' src='/imgs/IC_logo_sm.png' />
            </div>
          </div>
        </div>
      </div>


    ) : display === 'lccc_2' ? (
      <div id='lccc_2'>
        <div class='container'>
          <div class='row'>
            <div class='col-6 left'>
              <div class='team'>
                {Team1Display}
              </div>
              <div class='percent'>
                {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team1WinPercentage===null ?
                      '' : team1WinPercentage+'%' }
              </div>
            </div>
            <div class='col-6 right'>
              <div class='team'>
                {Team2Display}
              </div>
              <div class='percent'>
                {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team2WinPercentage===null ?
                      '' : team2WinPercentage+'%' }
              </div>
            </div>
          </div>
          {powerbar===true ? (
          <>
          <div class='row powerbar'>
            <div class='row'>
              <div class='col'>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" style={{width: `${team1WinPercentage}%`, backgroundColor: progressBarColor}}  aria-valuemin="0" aria-valuemax="100"></div>
                </div>
              </div>
            </div>
            

          </div>
          </>

          ) : ( <div></div> )
          }

          <div class='row'>
            <div class='col text-center powerby'>
              <img class='img-fluid' src='/imgs/IC_logo_sm.png' />
            </div>
          </div>
        </div>
      </div>


    ) : display === 'minimal' ? (
      <div id='minimal'>
        <div class='container'>
          {drawDisplay!==true ? (
          <div class='row'>
            <div class='col-6 left' style={{backgroundColor: bColor}}>
              <div class='team'>
                {Team1Display}
              </div>
              <div class='percent withdraw'>
                {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team1WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                    <>
                      <img class='c_ball' src='/imgs/cricket_ball_white.png' />
                      <span class="calc">{Winner}</span> 
                    </>
                    : team1WinPercentage+'%' }
              </div>
            </div>
            <div class='col-6 right' style={{backgroundColor: bColor}}>
              <div class='team'>
                {Team2Display}
              </div>
              <div class='percent withdraw'>
                 {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team2WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                    <>
                      <img class='c_ball' src='/imgs/cricket_ball_white.png' />
                      <span class="calc">Calculating</span> 
                    </>
                    : team2WinPercentage+'%' }
              </div>
            </div>
          </div>
          ) : (
          <div class='row'>
            <div class='col-5 left' style={{backgroundColor: bColor}}>
              <div class='team withdraw'>
                {Team1Display}
              </div>
              <div class='percent withdraw'>
                {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team1WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                    <>
                    <img class='c_ball' src='/imgs/cricket_ball_white.png' />
                    <span class="calc">Calculating</span>
                    </>
                    : team1WinPercentage+'%' }
              </div>
            </div>
            <div class='col-2 center' style={{backgroundColor: bColor}}>
              <div class='draw withdraw'>
                DRAW
              </div>
              <div class='drawpercent withdraw'>
              {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team1WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ? (
                <>
                </>
              ) : ( <>{((1000 - (team1WinPercentage*10) - (team2WinPercentage*10))/10)}%</> )}
              </div>
            </div>
            <div class='col-5 right' style={{backgroundColor: bColor}}>
              <div class='team withdraw'>
                {Team2Display}
              </div>
              <div class='percent withdraw'>
                 {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team2WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                    <>
                      <img class='c_ball' src='/imgs/cricket_ball_white.png' />
                      <span class="calc">Calculating</span>
                    </>
                    : team2WinPercentage+'%' }
              </div>
            </div>
          </div>
          )}
          {powerbar!==true || team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team2WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ? 
          <div class='row powerbarAlt' style={{backgroundColor: bColor}}></div> : 
          <>
          <div class='row powerbar' style={{backgroundColor: bColor}}>
            <div class='row'>
              <div class='col'>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" style={{width: `${team1WinPercentage}%`, backgroundColor: progressBarColor}}></div>
                  {drawDisplay === true ? (
                    <>
                    <div class="progress-bar" role="progressbar" style={{color: '#242424', width: `${100-team1WinPercentage-team2WinPercentage}%`, backgroundColor: '#bababa'}}></div>
                    <div class="progress-bar" role="progressbar" style={{width: `${team2WinPercentage}%`, backgroundColor: progressBarColorAlt}}></div>
                    </>
                  ) : (
                    <div class="progress-bar" role="progressbar" style={{width: `${100-team1WinPercentage}%`, backgroundColor: progressBarColorAlt}}></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          </>
          }
          <div class='row'>
            <div class='col text-center powerby' style={{borderColor: bColor}}>
              {showFlags!==true ? (
              <img class='img-fluid' src='/imgs/IC_logo_sm.png' alt='Intelligent Cricket' />
              ) : (
                <div class='row ng'>
                  <div class='col-2 text-start'>
                    <img class='flagicon' src={Team1Logo} alt={Team1Display} />                   
                  </div>
                  <div class='col-8'>
                    <img class='img-fluid' src='/imgs/IC_logo_sm.png' alt='Intelligent Cricket' />
                  </div>
                  <div class='col-2 text-end'>
                    <img class='flagicon' src={Team2Logo} alt={Team2Display} />                   
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>


    ) : display === 'tes2024' ? (
      <div id='tes2024' style={{width: `${widgetwidth}`}}>
        <div className='winpredict' style={{backgroundColor: `#${widgetbackground}`, borderColor: `#${widgetborder}`, animation: `${widgetanimate==='true' ? ('slideUpBackground 0.5s ease-out forwards') : ( 'none' )}`}}>
            <div className='line' style={
                                    {
                                      animation: `${widgetanimate==='true' ? ('slideUpLines 0.5s ease-out forwards') : ( 'none' )}`,
                                      opacity: `${widgetanimate==='true' ? ('0') : ( '1' )}`
                                      }}>
              <div className='col teamname'>{Team1Display}</div>
              <div className='col teamname'>{Team2Display}</div>
            </div>
            <div className='line' style={
                                    {
                                      animation: `${widgetanimate==='true' ? ('slideUpLines 0.5s ease-out forwards') : ( 'none' )}`,
                                      opacity: `${widgetanimate==='true' ? ('0') : ( '1' )}`
                                      }}>
              <div className='col teamwin'>
                {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team1WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                  <>
                    <img className='c_ball' src='/imgs/cricket_ball_white.png' />
                    <span className="calc">Calculating</span>
                  </>
                  : team1WinPercentage+'%' }
              </div>
              <div className='col teamwin'>
                {team1WinPercentage==='99.0' && team2WinPercentage==='99.0' || team2WinPercentage===null || team1WinPercentage==='99' && team2WinPercentage==='99' ?
                  <>
                    <img className='c_ball' src='/imgs/cricket_ball_white.png' />
                    <span className="calc">Calculating</span>
                  </>
                  : team2WinPercentage+'%' }
              </div>
            </div>
            <div className="progress">
              <div className="progress-bar" role="progressbar" style={{width: `${team1WinPercentage}%`, backgroundColor: progressBarColor}}></div>
              <div className="progress-bar" role="progressbar" style={{width: `${100-team1WinPercentage}%`, backgroundColor: progressBarColorAlt}}></div>
            </div>
            {widgetsponsor.toLowerCase()==='dafabet' ? (
              <div className='sponsor'>
                <img src='/imgs/dafabet_logo.png' style={{maxHeight: '100px'}} />
              </div>
            ) : ( null )}
        </div>        
      </div>
    ) : (
      <div id='broadcasting_hori'>
          <div class='container'>
            <div class='row'>
              <div class='col-5'>
                <div class='wrapper'>
                  <div clas='row'>
                    <div class='col'>
                      <h1>WIN PREDICT</h1>
                      <h2>Who will win?</h2>
                    </div>
                  </div>
                  <div class='row'>
                    <div class='col-6'>{Team1Display}</div>
                    <div class='col-6 text-end'>{team1WinPercentage}%</div>
                  </div>
                  <div class='row'>
                    <hr />
                  </div>
                  <div class='row'>
                    <div class='col-6'>{Team2Display}</div>
                    <div class='col-6 text-end'>{team2WinPercentage}%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      </div>
     )}
    </>
  );
  
}

export default Predictions;
